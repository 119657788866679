import "./style.css";
import Filter from "../../assets/img/filter.svg";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const FilterMobileComponent = ({ categorias,
  filtros,
  setFiltros,
  customFilter = null,
  filtrosPersonalizados = [],
  setFiltrosPersonalizados=null,
  esUnico=false,clearEvent=null}) => {
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [showPopover, setShowPopover] = React.useState(false);
  const [categoriaSeleccionada,setCategoriaSeleccionada]=React.useState()
  const [verTodos,setVerTodos]=React.useState([])

  const iniciarAnimacion = () => {
    return new Promise(function (resolve, reject) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
        resolve(true);
      }, 250); // Tiempo en milisegundos de la animación
    });
  };

  const seleccionarFiltro = async (subcategoria) => {
    await iniciarAnimacion();

    if (filtros.some((filtro) => filtro.id === subcategoria.id)) {
      setFiltros(filtros.filter((filtro) => filtro.id !== subcategoria.id));
      return;
    }
    setFiltros([...filtros, subcategoria]);
  };

  const eliminarSeleccionado = async (subcategoria) => {
    await iniciarAnimacion();    
    setFiltros(
      filtros.filter((filtro) => {
        return filtro.id !== subcategoria.id;
      })
    );
  };

  const eliminarSeleccionadoPersonalizado = async (id) => {
    
    await iniciarAnimacion();
    clearEvent()
    setFiltrosPersonalizados(
      filtrosPersonalizados.filter((filtro) => {
        return filtro.id !== id;
      })
    );
  };

  const cleanFilter=()=>{
    setFiltros([])
    setFiltrosPersonalizados([])
    clearEvent()
  }

  const popoverBottom = (
    <Popover
      className="popover-positioned-bottom popover-filtro-categorias"      
    >
      <div
        className={`contenedor-principal-filtros-mobile fadeInOut-custom ${
          isAnimating ? "fade-custom" : ""
        }`}
      >
        {filtros.length || filtrosPersonalizados.length  ? (
          <div className="mb-4">
            <div className="cabecera-filtro-mobile mb-3 mt-2">
              <img src={Filter} width={25} alt="filters" />{" "}
              <span id="titulo-filtro">Filtrado por:</span>
            </div>
            <div id="contenido-elementos-filtrados-mobile" className="">
              {filtros?.map((filtro) => {
                return (
                  <div className="elementos-filtrados">
                    <span>{filtro.nombre}</span>
                    <span
                      onClick={(e) => eliminarSeleccionado(filtro)}
                      className="eliminar-seleccionado"
                    >
                      X
                    </span>
                  </div>
                );
              })}

              {filtrosPersonalizados?.map((filtro) => {
              return (
                <div className="elementos-filtrados">
                  <span>{filtro.nombre}</span>
                  <span
                    onClick={(e) => eliminarSeleccionadoPersonalizado(filtro.id)}
                    className="eliminar-seleccionado"
                  >
                    X
                  </span>
                </div>
              );
            })}
            </div>
            <span id="borrar-filtros-mobile" onClick={(e) => cleanFilter()}>
              Borrar filtros
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className="cabecera-filtro-mobile  mb-3 mt-2">
          <img src={Filter} alt="filter" width={25} />{" "}
          <span id="titulo-filtro">Filtrar por:</span>
        </div>

        <div className="categories">
          <div className="ps-2">{customFilter}</div>
          {categorias?.map((categoria) => {
            return (
              <>
                <div className={"componente-categoria-mobile "+(categoriaSeleccionada!==categoria.id?"borde-categoria":"")} onClick={(e)=>setCategoriaSeleccionada(categoriaSeleccionada===categoria.id?null:categoria.id)}>
                  <span className="nombre-categoria">{categoria.nombre}</span>{" "}
                  <i className={"fas icono-arrow "+(categoriaSeleccionada===categoria.id?"fa-angle-up":"fa-angle-down")}></i>
                </div>
                {categoriaSeleccionada===categoria.id?
                <div className="grupo-subcategorias-mobile p-2">
                  {categoria?.subcategorias.sort((a, b) => {
                      // Comparamos los valores de la propiedad "nombre" de cada objeto
                      const nombreA = a.nombre.toUpperCase();
                      const nombreB = b.nombre.toUpperCase();

                      if (nombreA < nombreB) {
                        return -1; // Si a debe ir antes que b, devuelve un valor negativo
                      }
                      if (nombreA > nombreB) {
                        return 1; // Si b debe ir antes que a, devuelve un valor positivo
                      }
                      return 0; // Si son iguales, no cambia el orden
                    }).slice(0,verTodos.includes(categoria?.id)?categoria?.subcategorias.length:5).map((subcategoria) => {
                    
                      let display=esUnico && !filtros.some(item=>item.id===subcategoria.id) && filtros.length?"none":"block"
                      
                      if(esUnico && !filtros.some(item=>item.id===subcategoria.id) && filtros.length)
                      {
                        display="none"
                      }

                      return (
                      <div style={{display:display}}>
                        <label className="label-subcategoria">
                          <input
                            type="checkbox"
                            id={subcategoria.id}
                            className="checkbox"
                            checked={filtros.some(
                              (item) => item.id === subcategoria.id
                            )}
                            onClick={(e) => seleccionarFiltro(subcategoria)}
                          />
                          {subcategoria.nombre}
                        </label>
                      </div>
                    );
                  })}
                   {!(esUnico && filtros.length===1) && (!verTodos.includes(categoria.id) && categoria?.subcategorias?.length>5)?
                  <button
                  className="boton-filtros btn btn-hb-primary-without-border shadow-blue no-border sin-margen todos-subcategorias"
                  onClick={() => setVerTodos([...verTodos, categoria.id])}
                >
                  <span>Ver todos</span>
                </button>:categoria?.subcategorias?.length>5 && !(esUnico && filtros.length===1)?<button
                      className="todos-subcategorias boton-filtros mt-1 btn btn-hb-primary-without-border shadow-blue no-border sin-margen"
                      onClick={() =>
                        setVerTodos([
                          ...verTodos.filter((item) => item !== categoria.id),
                        ])
                      }
                    >
                      <span>Ver menos</span>
                    </button>:<></>
                  }
                </div>:<></>
                }

              </>
            );
          })}
        </div>
      </div>
    </Popover>
  );

  return (
    <div
      className={`contenedor-principal-filtros fadeInOut ${
        isAnimating ? "fade" : ""
      }`}
    >
      <OverlayTrigger
        trigger="click"
        onHide={() => setShowPopover(false)}
        rootClose={true}
        placement="bottom-start"
        show={showPopover}
        overlay={popoverBottom}        
      >
        <img
          src={Filter}
          width={25}
          className="filtro-principal-mobile"
          onClick={(e) => setShowPopover(!showPopover)}
          alt="filter"
        />
      </OverlayTrigger>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import dompurify from "dompurify";
import { useParams, Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { fetch } from "../../../utils/fetch";
import LocationIcon from "../../../assets/img/location-pin.png";
import CheckIcon from "../../../assets/img/check.png";
import Phone from "../../../assets/img/phone-como-llegar.png";
import "./ver-centros-medicos.css";
import HBOnlineColor from "../../../assets/img/hb-logo-small-color.png";
import HBOnlineTransparent from "../../../assets/img/hb-logo-small-transparent.png";
import DynamicCommonLoader from "../../../components/common-loader/dynamicCommonLoader";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "pageview",
    page: "Ver centros médicos",
  },
  dataLayerName: "PageDataLayer",
};

function VerCentroMedico() {
  TagManager.dataLayer(tagManagerArgs);
  const { id } = useParams();
  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);
  const [centrosMedicos, setCentrosMedicos] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [btnSeleccionado, setBtnSeleccionado] = React.useState(id);

  const getData = async () => {
    const { data } = await fetch(
      `centros-medicos/${id}?_locale=es&_publicationState=preview`,
      "GET"
    );
    setContenido(data);
  };
  const getCentrosMedicos = async () => {
    const { data } = await fetch(
      "centros-medicos?_locale=es&onOff=true&eliminado=false&_sort=orden:ASC",
      "GET"
    );
    setCentrosMedicos(data);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    if (id) {
      getData();
      getCentrosMedicos();
      setLoader(false);
    }
  }, [id]);

  function RenderAccordion({ items }) {
    return (
      <Accordion style={{ maxWidth: "unset" }}>
        {items &&
          items.map((data, index) => (
            <>
              {data.mostrar && (
                <Accordion.Item
                  key={index}
                  eventKey={index}
                  style={{ width: "100%" }}
                >
                  <Accordion.Header className="header-accordion">
                    {data.nombre}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      {data.items_acordion.map((itemsAcordion, index) => (
                        <div className="col-lg-4">
                          <img
                            src={CheckIcon}
                            alt="check-icon"
                            className="me-2"
                          />
                          {itemsAcordion.nombre}
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </>
          ))}
        <Accordion.Item
          key={"-1"}
          eventKey={"-1"}
          style={{ backgroundColor: "#004990" }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              padding: "1rem 1.25rem",
              color: "#fff",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            <div className="d-none d-md-block">
              Solicitá un nuevo turno, desde nuestro portal
            </div>
            <div className="d-block d-sm-none">Solicitá tu turno</div>

            <div>
              <a href="https://www.hbritanicoweb.com.ar" target="_blank">
                <button
                  type="button"
                  className="btn hb-online"
                  style={{ backgroundColor: "#fff", color: "#294f90" }}
                >
                  <span className="color">
                    <img src={HBOnlineColor} alt="" />
                  </span>
                  ONLINE
                </button>
              </a>
            </div>
          </div>
        </Accordion.Item>
      </Accordion>
    );
  }

  return (
    <>
      {loader ? (
        <DynamicCommonLoader dynamic={"225px"} />
      ) : (
        <section className="bg-gray">
          <div className="container-lg" style={{ maxWidth: "1300px" }}>
            <div className="row pt-5 pb-5">
              <div className="detalle-centro-medico">
                {contenido && (
                  <div className="card b-radius-20">
                    <div className="card-body">
                      <div className="topInfo">
                        <div>
                          <h2 className="como_llegar__titulo mb-4 mt-4">
                            Red de Centros
                          </h2>
                          <div className="d-md-block">
                            <div className="d-flex gap-3 mb-5 flex-column-mobile">
                              {centrosMedicos &&
                                centrosMedicos.map((data, index) => (
                                  <Link
                                    to={`/centros-medicos/ver/${data.id}`}
                                    type="button"
                                    key={index}
                                    onClick={() => setBtnSeleccionado(data.id)}
                                    className={`btn navItem__button--hb ${
                                      btnSeleccionado == data.id &&
                                      "btnSeleccionado"
                                    }`}
                                  >
                                    {data.nombre}
                                  </Link>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-lg-6 text-center">
                            <img
                              src={contenido?.imagen?.url}
                              className="img-fluid b-radius-20"
                              alt="..."
                            />
                          </div>
                          <div className="col-md-6 col-lg-6 detalle-centro-medico">
                            <div className="card card-ver-centros-medicos bg-transparent border-0">
                              <div className="card-bod">
                                <h5 className="title_reusable_components center-title">
                                  {contenido.nombre}
                                </h5>

                                <p className="card-text row">
                                  <div className="col-1">
                                    <img src={LocationIcon} alt="location" />
                                  </div>
                                  <div className="col-11">
                                    <b>{contenido.direccion}</b>
                                    <br />
                                    {contenido.localidad}
                                  </div>
                                </p>
                                <p className="card-text row">
                                  <div className="col-1">
                                    <img src={Phone} alt="phone" />
                                  </div>
                                  <div className="col-11">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: sanitizer(
                                          contenido.info_contacto
                                        ),
                                      }}
                                    />
                                  </div>
                                </p>
                              </div>
                              <div className="d-flex justify-content-end bg-transparent border-0">
                                <Link
                                  to={`/como-llegar/${contenido.id}`}
                                  className="btn btn-azul b-radius-20"
                                >
                                  Cómo Llego
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        {contenido.descripcion && (
                          <div className="row">
                            <h2 className="como_llegar__titulo mb-4 mt-4">
                              Descripción del centro
                            </h2>
                            <div className="detalle-centro-medico">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: sanitizer(contenido.descripcion),
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row pt-4">
                        <div className="col-lg-12 detalle-centro-medico">
                          <RenderAccordion items={contenido.acordion} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default VerCentroMedico;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
//import { useToggle } from '../../hooks/useToggle'
//import { PoliticaProteccionComponent } from './politica-proteccion';

// import IconFb from '../../assets/img/icon-fb.png'
// import IconInsta from '../../assets/img/icon-insta.png'
// import IconLinkedin from '../../assets/img/icon-linkedin.png'
// import IconTw from '../../assets/img/icon-tw.png'

import IconFb from "../../assets/img/redes/Facebook.png";
import IconInsta from "../../assets/img/redes/Instagram.png";
import IconLinkedin from "../../assets/img/redes/LinkedIn.png";
import IconTw from "../../assets/img/redes/Xfooter.svg";
import IconYt from "../../assets/img/redes/Youtube.png";

import FooterLocationPin from "../../assets/img/footer-location-pin.png";
import FooterPhone from "../../assets/img/footer-phone.png";
import FooterBook from "../../assets/img/footer-book.png";
import { useToggle } from "../../utils/useToggle";
import { PoliticaProteccionComponent } from "./politica-proteccion";
import LogoFooter from "../../assets/img/logo-hb-blak.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faBook,
  faCircleXmark,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
const FooterComponent = () => {
  const politicaToggle = useToggle();

  return (
    <section className="section-footer">
      <div className="container">
        <div className="row pt-3 pb-2">
          <div className="col-lg-6">
            <p className="d-flex justify-content-start align-items-center">
              {/* <span>
                                <img src={LogoFooter} alt="logo footer" className="img-fluid w-50" />
                            </span> */}
              <img
                src={LogoFooter}
                alt="logo footer"
                style={{ paddingRight: "5px", height: "16px" }}
              />
              <span className="section-footer__titulo">
                {" "}
                Hospital Británico | © 2024
              </span>
            </p>
            <p className="section-footer__texto">
              Sr. consumidor o usuario: ante cualquier duda o reclamo dirijase
              al área de defensa y protección al consumidor de cualquier Centro
              de Gestión y Participación Comunal (CGPC) del Gobierno de la
              Ciudad Autónoma de Bs. As. y/o al teléfono gratuito 147 de
              Gobierno de la Ciudad Autónoma de Buenos Aires.
            </p>
            <p className="section-footer__texto">
              Defensa de las y los consumidores. Para reclamos ingrese{" "}
              <a
                href="https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario"
                rel="noreferrer"
                target="_blank"
              >
                aqui.
              </a>
            </p>
          </div>
          <div className="col-lg-6">
            <div className="d-flex contact-social-networks">
              <div className="icons d-flex">
                <a
                  href="https://www.facebook.com/hospitalbritanico/"
                  target="_blank"
                >
                  <img
                    src={IconFb}
                    className="img-fluid px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a href="https://twitter.com/htal_britanico" target="_blank">
                  <img
                    src={IconTw}
                    className="img-fluid  px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/hospital-brit-nico-buenos-aires"
                  target="_blank"
                >
                  <img
                    src={IconLinkedin}
                    className="img-fluid px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
                <a
                  href="https://www.instagram.com/hospitalbritanicoarg/"
                  target="_blank"
                >
                  <img
                    src={IconInsta}
                    className="img-fluid px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>

                <a
                  href="https://www.youtube.com/hospitalbritanico"
                  target="_blank"
                >
                  <img
                    src={IconYt}
                    className="img-fluid px-1 footer-icon"
                    alt="icon-fb"
                    style={{}}
                  />
                </a>
              </div>
              <div className="section-footer__privacidad">
                <span className="section-footer__texto">
                  <a
                    href="javascript:void(0)"
                    onClick={politicaToggle.onToggle}
                  >
                    POLITICA DE PRIVACIDAD
                  </a>{" "}
                  |{" "}
                  <a
                    target={"_blank"}
                    className="link-default"
                    rel={"noreferrer"}
                    href="https://home.hbritanico.com.ar/Account/LogOn"
                  >
                    INTRANET
                  </a>
                </span>
                <PoliticaProteccionComponent
                  visible={politicaToggle.toggle}
                  onToggle={politicaToggle.onToggle}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="d-flex flex-column mx-2 mt-3 contact-information">
                  <div className="icon-location">
                    <img
                      src={FooterLocationPin}
                      className="img-fluid"
                      alt="icon-fb"
                      style={{}}
                    />
                    <span className="ms-2 section-footer__texto">
                      Perdriel 74 - CP 1280AEB - Ciudad Autónoma de Buenos Aires
                    </span>
                  </div>
                  <div className="icon-phone">
                    <img
                      src={FooterPhone}
                      className="img-fluid"
                      alt="icon-fb"
                      style={{}}
                    />
                    <a href="tel:(011) 4309-6400">
                      <span className="ms-2 section-footer__texto">
                        (011) 4309-6400
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column mx-2 mt-3 contact-information">
                  <div className="icon-book">
                    <FontAwesomeIcon icon={faBook} color="#0f86c3" />
                    <span className="ms-2 section-footer__texto">
                      <Link to={"/contacto"}>Libro de sugerencias</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FooterComponent;

/* eslint-disable eqeqeq */
import React from "react";
import { fetch } from "../../utils/fetch";
import "./novedades.css";
import ArrowBlue from "../../assets/img/arrow_blue.png";
import ArrowWhite from "../../assets/img/arrow_white.png";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";
import { FilterDesktopComponent } from "components/filtro/desktop";
import { FilterMobileComponent } from "components/filtro/mobile";
import Filter from "../../assets/img/filter.svg";

const tagManagerArgs = {
  dataLayer: {
    event: "pageview",
    page: "Novedades",
  },
  dataLayerName: "PageDataLayer",
};

const NovedadesPage = () => {
  TagManager.dataLayer(tagManagerArgs);
  const [contenido, setContenido] = React.useState(null);
  const [categorias, setCategorias] = React.useState(null);
  const [filtros, setFiltros] = React.useState([]);

  const [isMobile, setIsMobile] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [itemsPerPage, setItemsPerPage] = React.useState(9);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isLoading, setIsloading] = React.useState(false);

  React.useEffect(() => {
    // Función para verificar el tamaño de la ventana y establecer el estado isMobile
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth <= 768); // Ajusta el ancho según tu criterio para considerar "móvil"
    };

    // Agrega un event listener para actualizar isMobile cuando cambie el tamaño de la ventana
    window.addEventListener("resize", checkWindowSize);

    // Llama a la función inicialmente para establecer el valor de isMobile
    checkWindowSize();

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    // getData();
    getFiltros();
  }, []);

  const getData = async () => {
    setIsloading(true);
    const { data } = await fetch(`novedades/consultar/porCategorias`, "POST", {
      subcategorias: filtros.map((filtro) => filtro.id),
      itemsPerPage,
      pageNumber,
    });
    setContenido(data);
    setIsloading(false);
  };

  React.useEffect(() => {
    getData();
  }, [pageNumber]);

  React.useEffect(() => {
    getData();
  }, [filtros]);

  const getFiltros = async () => {
    const { data } = await fetch(
      `categorias/subcategorias/all?seccion=novedades`,
      "GET"
    );
    // console.log("CATEGORIAS", data);
    setCategorias(data);
  };

  function Opc2({ fila, columna, data }) {
    return (
      <div
        className={`card b-radius-10 p-2 card-novedades ${
          fila == columna && "bg-azul"
        }  `}
      >
        <div className="card-body">
          {/* Foto */}
          {data.imagen != null ? (
            <div className="card-foto opc2" style={{ aspectRatio: "600/270" }}>
              <img
                src={data?.imagen?.url}
                alt="novedad"
                className="b-radius-10"
                style={{ objectFit: "cover", height: "100%" }}
              />
            </div>
          ) : (
            <div
              className="card-title novelty-main-title"
              style={{
                color: fila == columna ? "white" : "#05294f",
                height: "100px",
                fontSize: "22px",
              }}
            >
              {data.titulo.substr(0, 73)} {data.titulo.length > 73 && <>...</>}
            </div>
          )}
          {data.imagen == null ? (
            <hr
              class="separador"
              style={{
                backgroundColor: fila == columna ? "white" : "#05294f",
                height: "3px",
                opacity: "1",
              }}
            />
          ) : (
            <></>
          )}
          {/* Titulo */}
          {data.imagen == null ? (
            <div
              className="card-descripcion-corta opc1"
              style={{ color: fila == columna ? "white" : "#05294f" }}
            >
              {/* {data.descripcionCorta.substr(0, 80)} {data.titulo.length > 80 && <>...</>} */}
              {data.descripcionCorta}
            </div>
          ) : (
            <div
              className="card-descripcion-corta opc1"
              style={{
                color: fila == columna ? "white" : "#05294f",
                fontWeight: "bold",
              }}
            >
              {data.titulo}
            </div>
          )}
        </div>
        <div className="card-footer opc2 d-flex bg-transparent border-0 justify-content-end">
          {fila == columna ? (
            <>
              <img src={ArrowWhite} alt="arrow" />{" "}
            </>
          ) : (
            <>
              <img src={ArrowBlue} alt="arrow" />{" "}
            </>
          )}
        </div>
      </div>
    );
  }
  /*
    -opc3
    no lleva foto
    lleva titulo
    lleva descripcion corta
    */
  function Opc3({ fila, columna, data }) {
    return (
      <div className={`card b-radius-10 p-2 card-novedades  `}>
        <div className="card-body">
          {/* Titulo */}
          <div className="card-title novelty-main-title opc3">
            {data.titulo.substr(0, 80)} {data.titulo.length > 80 && <>...</>}
          </div>
          <hr className="separador opc3" />
          {/* Descripcion Corta */}
          <div className="card-descripcion-corta opc3">
            {data.descripcionCorta}
          </div>
        </div>
        <div className="card-footer d-flex bg-transparent border-0 justify-content-end">
          {fila == columna ? (
            <>
              <img src={ArrowWhite} alt="arrow" />{" "}
            </>
          ) : (
            <>
              <img src={ArrowBlue} alt="arrow" />{" "}
            </>
          )}
        </div>
      </div>
    );
  }

  const siguiente = () => {
    window.scrollTo({ top: 0 });
    setPageNumber(pageNumber + 1);
  };

  const anterior = () => {
    window.scrollTo({ top: 0 });
    setPageNumber(pageNumber - 1);
  };

  return (
    <section className="bg-novedades">
      <div className="container">
        <div className="row pt-5 pb-3">
          <div className="col-lg-12 titulo-novedades">
            <h2 className="title_novedades_h2">Novedades</h2>
            {isMobile ? (
              <div>
                <FilterMobileComponent
                  categorias={categorias}
                  filtros={filtros}
                  setFiltros={setFiltros}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
            {!isMobile ? (
              <FilterDesktopComponent
                categorias={categorias}
                filtros={filtros}
                setFiltros={setFiltros}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12">
            <div className="row">
              {contenido?.data &&
                contenido?.data.map((data, index) => {
                  let fila = parseInt(index / 3) % 3;
                  let columna = index % 3;

                  return (
                    <div className="col-lg-4 mb-4" key={index}>
                      {/* Validaciones Opc1  */}
                      {fila == columna && (
                        <Link to={"/novedades/" + data.slug}>
                          <Opc2 data={data} fila={fila} columna={columna} />
                        </Link>
                      )}
                      {/* Validaciones Opc2  */}
                      {fila !== columna && data.foto !== "" && (
                        <Link to={"/novedades/" + data.slug}>
                          <Opc2 data={data} fila={fila} columna={columna} />
                        </Link>
                      )}
                      {/* Validaciones Opc3  */}
                      {fila !== columna && data.foto == "" && (
                        <Link to={"/pages/novedades/" + data.slug}>
                          <Opc3 data={data} fila={fila} columna={columna} />
                        </Link>
                      )}
                    </div>
                  );
                })}
            </div>
            {contenido?.count > 1 ? (
              <div id="paginate" className="pb-2">
                <button
                  className="btn btn-hb-primary-without-border shadow-blue no-border boton-paginacion"
                  onClick={isLoading ? window.scrollTo({ top: 0 }) : anterior}
                  disabled={!(pageNumber > 1)}
                >
                  Anterior
                </button>
                <span className="number-hb-paginate">
                  <a href="#">{pageNumber}</a>
                </span>

                <button
                  className="btn btn-hb-primary-without-border shadow-blue no-border boton-paginacion"
                  onClick={isLoading ? window.scrollTo({ top: 0 }) : siguiente}
                  disabled={!(pageNumber < contenido?.count)}
                >
                  Siguiente
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default NovedadesPage;

import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    nombre: Yup.string().required('Campo obligatorio'),
    apellido: Yup.string().required('Campo obligatorio'),    
    email: Yup.string().email('Email inválido').required('Campo obligatorio'),    
    telefono: Yup.string().required('Campo obligatorio'),      
    opcion: Yup.string().required('Campo obligatorio'),    
    comentario: Yup.string().required('Campo obligatorio'),        
    aceptoPrivacidad: Yup.bool().required('Campo obligatorio'),        
    validateAceptoPrivacidad: Yup.string().when('aceptoPrivacidad', {
        is: false,
        then: Yup.string().required("Campo obligatorio")
    })       
});
import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const Acordeon = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className={`accordion-item ${
        isActive && content[0] ? "accordion-item-open" : ""
      }`}
    >
      <div
        className={`accordion-title ${
          content[0] ? "" : "accordion-title-empty"
        }`}
        onClick={() => content && setIsActive(!isActive)}
      >
        <div>{title}</div>
        {content[0] && (
          <div>
            {isActive ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
          </div>
        )}
      </div>
      {isActive && content[0]
        ? content.map(({ nombre }, index) => {
            return (
              <div
                className={`accordion-content ${
                  isActive ? "accordion-open" : ""
                }`}
                style={{ marginTop: index === 0 ? "15px" : "0" }}
              >
                {nombre}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default Acordeon;

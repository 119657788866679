import "./contacto.css";
import contacto from "../../assets/img/bg-contacto2.jpg";
import bannerContacto from "../../assets/img/bannerContactoPSHB.png";
import data_json from "./data.json";

import React, { useRef, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import ReactGA from "react-ga4";
import Swal from "sweetalert2";

import { PoliticaProteccionComponent } from "../../components/footer/politica-proteccion";
import { validateModel } from "./validationSchema";
import { fetch } from "../../utils/fetch";
import { useToggle } from "../../hooks/useToggle";
import { RECAPTCHA } from "../../utils/constants";

const tagManagerArgs = {
  dataLayer: {
    event: "pageview",
    page: "Contacto",
  },
  dataLayerName: "PageDataLayer",
};

const ContactoPage = () => {
  const captcha = useRef(null);
  const politicaToggle = useToggle();
  const [motivos] = useState(data_json.filter((x) => x.status));

  TagManager.dataLayer(tagManagerArgs);

  const onSubmit = async (values, resetForm) => {
    const destino = motivos.find((x) => x.motivo == values.opcion);

    // SE COMENTA PARA REALIZAR PRUEBAS EN QA
    if (captcha.current?.getValue()) {
      const correo = {
        to: destino.to.toLowerCase(),
        subject: "Solicitud de contacto - " + values.opcion,
        text: `<b>Nombre</b> ${values.nombre} <br/>
        <b>Apellido</b> ${values.apellido} <br/>
        <b>Opción</b> ${values.opcion} <br/>
        <b>Email</b> ${values.email} <br/>
        <b>Teléfono</b> ${values.telefono} <br/>    
        <b>Comentario</b> ${values.comentario} <br/>
        `,
      };

      // Send ppal mail
      await fetch(`send-mail`, "post", correo);

      // Send cc
      if (destino.cc) {
        correo.to = destino.cc.toLowerCase();
        await fetch(`send-mail`, "post", correo);
      }

      ReactGA.event({
        category: `Formulario Contacto`,
        action: "Clic",
        label: "Envío de Formulario",
      });

      resetForm();
      captcha.current?.reset();
      Swal.fire("Info!", "Solicitud de contacto enviada con éxito", "info");
      return;
    }

    Swal.fire(
      "Info!",
      "¡Ups! Parece que olvidaste marcar la casilla 'No soy un robot'",
      "info"
    );
  };

  const initialValues = {
    nombre: "",
    apellido: "",
    email: "",
    telefono: "",
    opcion: "",
    comentario: "",
    aceptoPrivacidad: false,
    validateAceptoPrivacidad: "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onSubmit(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <div className="container">
            <section className="section_contacto pb-5">
              <div className="container-fluid p-3">
                {/* BANNER SOLO EN MOBILE */}
                <div className="row col-12" style={{ paddingLeft: 0 }}>
                  <a
                    href="https://plandesalud.hospitalbritanico.org.ar/dejanos-tu-consulta"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={bannerContacto}
                      className="img-card-mobile pb-3"
                      style={{
                        height: "100%",
                        width: "104%",
                        objectFit: "cover",
                      }}
                      alt="banner-contacto"
                    />
                  </a>
                </div>

                {/* TITULO */}
                <div className="row pb-3">
                  <div className="col-lg-12">
                    <h2 className="section_contacto_titulo">
                      <b>Atención al paciente</b>
                    </h2>
                    <p className="subtitulo">
                      El Hospital Británico pone a su disposición diversos
                      medios para que puedan canalizar su agradecimiento,
                      sugerencia o reclamo.
                    </p>
                  </div>
                </div>

                {/* FORM */}
                <div className="card b-radius-20 shadow-external">
                  <div className="row g-0">
                    {/* FORMULARIO */}
                    <div className="col-12 col-lg-6 container-dejanos-consulta">
                      <form className="section_contacto_form">
                        <h2 className="section_contacto_form_titulo mb-4 mt-4">
                          <b>Dejanos tu consulta</b>
                        </h2>
                        <div className="mb-2 row align-items-center">
                          <div className="col-lg-2">
                            <label className="">Nombre</label>
                          </div>
                          <div className="col-lg-10">
                            <input
                              type="text"
                              name="nombre"
                              value={values.nombre}
                              onChange={handleChange}
                              className="form-control b-radius-20 shadow-blue align-items-center"
                            />
                          </div>
                          <div className="col-lg-10">
                            <div className="text-danger text-danger-custom">
                              {errors?.nombre}
                            </div>
                          </div>
                        </div>

                        <div className="mb-2 row align-items-center">
                          <div className="col-lg-2">
                            <label className="">Apellido</label>
                          </div>
                          <div className="col-lg-10">
                            <input
                              type="text"
                              name="apellido"
                              value={values.apellido}
                              onChange={handleChange}
                              className="form-control b-radius-20 shadow-blue"
                            />
                          </div>
                          <div className="col-lg-10">
                            <div className="text-danger text-danger-custom">
                              {errors?.apellido}
                            </div>
                          </div>
                        </div>

                        <div className="mb-2 row align-items-center">
                          <div className="col-lg-2">
                            <label className="">Email</label>
                          </div>
                          <div className="col-lg-10">
                            <input
                              type="text"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              className="form-control b-radius-20 shadow-blue"
                            />
                          </div>
                          <div className="col-lg-10">
                            <div className="text-danger text-danger-custom">
                              {errors?.email}
                            </div>
                          </div>
                        </div>

                        <div className="mb-2 row align-items-center">
                          <div className="col-lg-2">
                            <label className="">Teléfono</label>
                          </div>
                          <div className="col-lg-10">
                            <input
                              type="text"
                              name="telefono"
                              value={values.telefono}
                              onChange={handleChange}
                              className="form-control b-radius-20 shadow-blue"
                            />
                          </div>
                          <div className="col-lg-10">
                            <div className="text-danger text-danger-custom">
                              {errors?.telefono}
                            </div>
                          </div>
                        </div>

                        <div className="mb-2 row align-items-center">
                          <div className="col-lg-12">
                            <label className="mb-2">
                              Motivo de la consulta / Área a contactar
                            </label>
                            <select
                              name="opcion"
                              value={values.opcion}
                              onChange={handleChange}
                              className="form-control b-radius-20 shadow-blue"
                            >
                              {motivos.map((item) => (
                                <option key={item.id}>{item.motivo}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-10">
                            <div className="text-danger">{errors?.opcion}</div>
                          </div>
                        </div>

                        <div className="mb-2 row align-items-center">
                          <div className="col-lg-12">
                            <label className="mb-2">
                              Por favor, escriba su comentario
                            </label>
                            <textarea
                              name="comentario"
                              value={values.comentario}
                              onChange={handleChange}
                              className="form-control b-radius-20 shadow-blue"
                              rows="4"
                            ></textarea>
                          </div>
                          <div className="col-lg-10">
                            <div className="text-danger">
                              {errors?.comentario}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-2 form-check">
                              <input
                                name="aceptoPrivacidad"
                                value={values.aceptoPrivacidad}
                                onChange={handleChange}
                                className="form-check-input"
                                type="checkbox"
                                defaultValue
                                id="defaultCheck1"
                              />
                              <label
                                class="form-check-label"
                                for="defaultCheck1"
                              >
                                Acepto la{" "}
                                <a href="#" onClick={politicaToggle.onToggle}>
                                  política de privacidad
                                </a>{" "}
                                del Hospital Británico
                              </label>
                              <div className="text-danger">
                                {errors?.validateAceptoPrivacidad}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div id="botCaptcha">
                              <ReCAPTCHA ref={captcha} sitekey={RECAPTCHA} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mt-4 mb-4">
                              <button
                                type="button"
                                onClick={() => handleSubmit()}
                                className="btn_section_contacto b-radius-20 "
                              >
                                Enviar
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    {/* IMAGEN SOLO EN PC */}
                    <div className="col-lg-6">
                      <img
                        src={contacto}
                        className="b-radius-r-20 img-card"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt="bg-contacto"
                      />
                    </div>
                  </div>
                </div>

                {/* MEDIOS DE CONTACTO */}
                <div className="row mt-4">
                  <div className="col-12 col-lg-6">
                    <h2 className="section_contacto_titulo">
                      <b>Oficina Virtual de Atención al Paciente</b>
                    </h2>
                    <p className="subtitulo">
                      Para realizar agradecimientos, reclamos y sugerencias,
                      ponemos a disposición los siguientes canales:
                      <br />
                    </p>
                    <h4 className="subtitulo">
                      <span>
                        Portal WEB:{" "}
                        <Link to="/" className="info-contacto">
                          www.hospitalbritanico.org.ar
                        </Link>
                      </span>
                      <br />
                      <span>WhatsApp:</span>{" "}
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://wa.me/541130159749"
                      >
                        1130159749
                      </a>
                      <br />
                    </h4>
                  </div>
                  {/* BANNER SOLO EN PC */}
                  <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                    <a
                      href="https://plandesalud.hospitalbritanico.org.ar/dejanos-tu-consulta"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={bannerContacto}
                        className="img-card"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt="banner-contacto"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </Formik>
      <PoliticaProteccionComponent
        visible={politicaToggle.toggle}
        onToggle={politicaToggle.onToggle}
      />
    </>
  );
};
export default ContactoPage;

/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { fetch } from "../../utils/fetch";
import "./prensa.css";
import ArrowBlue from "../../assets/img/arrow_blue.png";
import ArrowWhite from "../../assets/img/arrow_white.png";
import Filter from "../../assets/img/filter.svg";

import { Link, json } from "react-router-dom";
import TagManager from "react-gtm-module";
import Select from "react-select";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FilterDesktopComponent } from "components/filtro/desktop";
import { FilterMobileComponent } from "components/filtro/mobile";
import Swal from "sweetalert2";
import { object } from "yup";

const tagManagerArgs = {
  dataLayer: {
    event: "pageview",
    page: "Prensa",
  },
  dataLayerName: "PageDataLayer",
};

const customStylesMes = {
  padding: "0px",
  option: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    paddingLeft: 5,
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: "0px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px",
    paddingLeft: "5px",
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    width: 105,
  }),
  input: (provided, state) => ({
    ...provided,
  }),
  menuList: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Oculta la flecha
  }),
  placeholder: (provided, state) => ({
    ...provided,
  }),
};

const customStylesAnio = {
  padding: "0px",
  option: (provided, state) => ({
    ...provided,
    borderRadius: "0px",
    paddingLeft: 5,
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: "0px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px",
    paddingLeft: "5px",
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: "none",
    width: 80,
  }),
  input: (provided, state) => ({
    ...provided,
  }),
  menuList: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none", // Oculta la flecha
  }),
  placeholder: (provided, state) => ({
    ...provided,
  }),
};

const meses = [
  { value: "01", label: "Enero" },
  { value: "02", label: "Febrero" },
  { value: "03", label: "Marzo" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Mayo" },
  { value: "06", label: "Junio" },
  { value: "07", label: "Julio" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" },
];

const current = new Date();
const currentYear = current.getFullYear();
const anios = Array.from(
  { length: currentYear - 2019 },
  (_, index) => currentYear - index
).map((item) => {
  return { value: item, label: item };
});

const PrensaPage = () => {
  TagManager.dataLayer(tagManagerArgs);
  const [contenido, setContenido] = React.useState(null);
  // const mesActual=meses.find(item=>item.value===`${current.getMonth()+1}`);
  const [mes, setMes] = React.useState();
  const [anio, setAnio] = React.useState();
  const [esFiltrado, setEsFiltrado] = React.useState(false);
  const [start, setStart] = React.useState(0);
  const [limit, setLimit] = React.useState(9);
  const [showPopover, setShowPopover] = React.useState(false);

  const [categorias, setCategorias] = React.useState(null);
  const [filtros, setFiltros] = React.useState([]);
  const [filtrosPersonalizados, setFiltrosPersonalizados] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    // Función para verificar el tamaño de la ventana y establecer el estado isMobile
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth <= 768); // Ajusta el ancho según tu criterio para considerar "móvil"
    };

    // Agrega un event listener para actualizar isMobile cuando cambie el tamaño de la ventana
    window.addEventListener("resize", checkWindowSize);

    // Llama a la función inicialmente para establecer el valor de isMobile
    checkWindowSize();

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  // React.useEffect(()=>{
  //     getData()
  //   },[])

  const getMedios = async () => {
    const prensas = await fetch(`prensas/medios/all`, "get");

    const items = [
      {
        nombre: "Medios",
        subcategorias: prensas.data.medios
          .filter((item) => item != null)
          .map((item) => {
            return { id: item, nombre: item };
          }),
      },
    ];

    setCategorias(items);
  };

  React.useState(() => {
    getMedios();
  }, []);

  const getData = async () => {
    let url = `prensas/paginacion/total?_limit=${limit}&_start=${start}`;

    if (filtrosPersonalizados.length) {
      url += `&fecha=${filtrosPersonalizados[0].id}`;
    }

    if (filtros[0] != undefined) {
      url += `&medio=${filtros[0]?.id}`;
    }

    const { data, headers } = await fetch(url, "GET");
    const prensas = data.prensas;

    setContenido({
      count: data.totalCount,
      data: prensas,
    });

    window.scrollTo(0,0)
  };

  useEffect(() => {
    getData();
  }, [start, filtros, filtrosPersonalizados]);

  // React.useEffect(()=>{
  //     getData()
  // },[mes,anio])

  // const aplicarFiltro = () => {
  //   setStart(0);
  //   setShowPopover(false);

  //   if (mes == undefined || anio == undefined || mes === "" || anio === "") {
  //     return;
  //   }
  //   setEsFiltrado(true);
  //   getData();
  // };

  // const limpiarFiltro = async () => {
  //   setStart(0);
  //   setMes("");
  //   setAnio("");
  //   setShowPopover(false);
  //   const { data } = await fetch(
  //     `prensas/paginacion/total?_limit=${limit}&_start=${start}`,
  //     "GET"
  //   );
  //   setEsFiltrado(false);
  //   setContenido({
  //     count: data.totalCount,
  //     data: data.prensas.sort(compareByDateDesc),
  //   });
  // };

  /*
    -opc1
    no lleva foto
    lleva titulo
    lleva descripcion corta
    */
  function Opc1({ fila, columna, data }) {
    return (
      <a href={data.url} rel="noreferrer" target="_blank">
        <div
          className={`card b-radius-10 p-2 card-prensas ${
            fila == columna && "bg-azul"
          }  `}
        >
          <div className="card-body">
            <div className="card-descripcion-corta opc1">
              {data.titulo.substr(0, 80)} {data.titulo.length > 80 && <>...</>}
            </div>
          </div>
          <div className="card-footer">
            <div className="separador-prensa-opc1 prensa-contenedor-footer pt-3 d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span>{data.fecha}</span>
                <div className="separador-textos separador-textos-azul" />
                <span>{data.medio}</span>
              </div>
              <img src={ArrowWhite} alt="arrow" />
              {/* <div className='d-flex bg-transparent border-0 justify-content-end'> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </a>
    );
  }
  /*
    -opc2
    lleva foto
    lleva titulo
    no lleva descripcion corta
    */
  function Opc2({ fila, columna, data }) {
    return (
      <a
        href={data.url}
        rel="noreferrer"
        target="_blank"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div className={`card b-radius-10 p-2 card-prensas`}>
          <div className="card-body">
            <div className="card-descripcion-corta opc1">
              {data.titulo.substr(0, 80)} {data.titulo.length > 80 && <>...</>}
            </div>
          </div>
          <div className="card-footer">
            <div className="separador-prensa-opc2 prensa-contenedor-footer pt-3 d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span>{data.fecha}</span>
                <div className="separador-textos separador-textos-blanco" />
                <span>{data.medio}</span>
              </div>
              <img src={ArrowBlue} alt="arrow" />
              {/* <div className='d-flex bg-transparent border-0 justify-content-end'> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </a>
    );
  }

  const siguiente = () => {
    setStart(start + limit);
  };

  const anterior = () => {
    setStart(start - limit);
  };

  // const popoverBottom = (
  //   <Popover className="popover-positioned-bottom" title="Popover bottom">
  //     <div
  //       style={{ padding: "20px", display: "flex", flexDirection: "column" }}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           justifyContent: "space-between",
  //           marginBottom: "20px",
  //         }}
  //       >
  //         <span
  //           style={{
  //             color: "#05294f",
  //             fontFamily: "Assistant-VariableFont_wght",
  //           }}
  //         >
  //           Fecha:
  //         </span>
  //         {esFiltrado && (
  //           <span
  //             style={{ cursor: "pointer", userSelect: "none" }}
  //             onClick={limpiarFiltro}
  //           >
  //             Limpiar filtros X
  //           </span>
  //         )}
  //       </div>
  //       <div style={{ display: "flex", paddingBottom: "10px" }}>
  //         <Select
  //           options={meses}
  //           placeholder="Mes"
  //           styles={customStylesMes}
  //           name="mes"
  //           value={mes}
  //           onChange={(e) => setMes(e)}
  //         />
  //         &nbsp;
  //         <Select
  //           options={anios}
  //           placeholder="Año"
  //           styles={customStylesAnio}
  //           name="anio"
  //           value={anio}
  //           onChange={(e) => setAnio(e)}
  //         />
  //       </div>
  //       <div style={{ display: "flex", justifyContent: "end" }}>
  //         <button
  //           type="button"
  //           className="btn button-filter-prensa"
  //           onClick={aplicarFiltro}
  //           disabled={anio === undefined || mes === undefined}
  //         >
  //           Aplicar
  //         </button>
  //       </div>
  //     </div>
  //   </Popover>
  // );

  const aplicarFiltrosPersonalizados = (e) => {
    let errores = {};
    if (mes === undefined) {
      errores.mes = 1;
    }

    if (anio === undefined) {
      errores.anio = 1;
    }

    setErrors(errores);
    if (Object.keys(errores).length > 0) return;
    // Swal.fire("Advertencia", "Debe de seleccionar mes y año para realizar el filtrado", "warning");

    const id = mes.value + "/" + anio.value;
    const nombre = mes.label + " " + anio.label;

    setFiltrosPersonalizados([{ id, nombre }]);
  };

  const CustomFilter = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>Fecha:</span>
        <div style={{ display: "flex", gap: "5px" }}>
          <div>
            <span>Mes</span>
            <Select
              options={meses}
              placeholder=""
              styles={customStylesMes}
              name="mes"
              value={mes}
              onChange={(e) => setMes(e)}
              className={errors.mes ? "con-error" : ""}
            />
          </div>

          <div>
            <span>Año</span>
            <Select
              options={anios}
              placeholder=""
              styles={customStylesAnio}
              name="anio"
              value={anio}
              onChange={(e) => setAnio(e)}
              className={errors.anio ? "con-error" : ""}
            />
          </div>
        </div>

        {errors.anio || errors.mes ? (
          <span style={{ color: "red" }}>*Campo obligatorio</span>
        ) : (
          <></>
        )}

        <div className="d-block pt-3 pb-3 ">
          <button            
            className="boton-aplicar btn btn-hb-primary-without-border shadow-blue no-border sin-margen"
            onClick={aplicarFiltrosPersonalizados}
          >
            Aplicar
          </button>
        </div>
      </div>
    );
  };

  const clearEvent = () => {
    setMes();
    setAnio();
  };

  return (
    <section className="bg-novedades" style={{ height: "100%" }}>
      <div className="container">
        <div className="row pt-5 pb-3">
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 className="title_novedades_h2">Prensa</h2>
            {isMobile ? (
              <div>
                <FilterMobileComponent
                  categorias={categorias}
                  filtros={filtros}
                  setFiltros={setFiltros}
                  customFilter={<CustomFilter />}
                  filtrosPersonalizados={filtrosPersonalizados}
                  setFiltrosPersonalizados={setFiltrosPersonalizados}
                  esUnico={true}
                  clearEvent={clearEvent}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
            {!isMobile ? (
              <FilterDesktopComponent
                categorias={categorias}
                filtros={filtros}
                setFiltros={setFiltros}
                customFilter={<CustomFilter />}
                filtrosPersonalizados={filtrosPersonalizados}
                setFiltrosPersonalizados={setFiltrosPersonalizados}
                esUnico={true}
                clearEvent={clearEvent}
              />
            ) : (
              <></>
            )}
          </div>
          <div className="col-xxl-10 col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
            <div class="row">
              {contenido &&
                contenido?.data?.map((data, index) => {
                  let fila = parseInt(index / 3) % 3;
                  let columna = index % 3;

                  //   console.log("INDEX: "+index+", FILA: "+fila+", COLUMNA:"+columna);
                  return (
                    <div className="col-lg-4 mb-4" key={index}>
                      {/* Validaciones Opc1  */}
                      {fila == columna && (
                        <Opc1 data={data} fila={fila} columna={columna} />
                      )}
                      {/* Validaciones Opc2  */}
                      {fila !== columna && data.foto !== "" && (
                        <Opc2 data={data} fila={fila} columna={columna} />
                      )}
                      {/* Validaciones Opc3  */}
                      {fila !== columna && data.foto == "" && (
                        <Opc2 data={data} fila={fila} columna={columna} />
                      )}
                    </div>
                  );
                })}

              {contenido?.count > 9 ? (
                <div id="paginate">
                  <button
                    disabled={!(start > 0)}
                    className="btn btn-hb-primary-without-border shadow-blue no-border"
                    onClick={anterior}
                  >
                    Anterior
                  </button>

                  <span className="number-hb-paginate">
                    <a href="#">{start / limit + 1}</a>
                  </span>

                  <button
                    disabled={
                      !(start + contenido?.data?.length < contenido?.count)
                    }
                    className="btn btn-hb-primary-without-border shadow-blue no-border"
                    onClick={siguiente}
                  >
                    Siguiente
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PrensaPage;

export const mySort = (data, field) => {
  data.sort((a, b) => {
    if (a[field] === undefined && b[field] === undefined) {
      return 0;
    } else if (a[field] === undefined) {
      return 1;
    } else if (b[field] === undefined) {
      return -1;
    } else if (a[field] === null && b[field] === null) {
      return 0;
    } else if (a[field] === null) {
      return 1;
    } else if (b[field] === null) {
      return -1;
    } else {
      return a[field] - b[field];
    }
  });
};

export const mySortMenuItems = (data) => {
  data.columna1.sort((a, b) => {
    if (a.order === undefined && b.order === undefined) {
      return 0;
    } else if (a.order === undefined) {
      return 1;
    } else if (b.order === undefined) {
      return -1;
    } else if (a.order === null && b.order === null) {
      return 0;
    } else if (a.order === null) {
      return 1;
    } else if (b.order === null) {
      return -1;
    } else {
      return a.order - b.order;
    }
  });

  data.columna2.sort((a, b) => {
    if (a.order === undefined && b.order === undefined) {
      return 0;
    } else if (a.order === undefined) {
      return 1;
    } else if (b.order === undefined) {
      return -1;
    } else if (a.order === null && b.order === null) {
      return 0;
    } else if (a.order === null) {
      return 1;
    } else if (b.order === null) {
      return -1;
    } else {
      return a.order - b.order;
    }
  });
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { fetch } from '../../utils/fetch';
import Template1Component from '../../components/templates/template1';
import Template2Component from '../../components/templates/template2';
import Template3Component from '../../components/templates/template3';
import DynamicCommonLoader from '../../components/common-loader/dynamicCommonLoader';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  dataLayer: {
      event:"pageview",
      page: 'Templates'
  },
  dataLayerName: 'PageDataLayer'
}

function PageTemplate() {  

TagManager.dataLayer(tagManagerArgs)

  const [dataResult, setDataResult] = React.useState(null);
  const params = useParams();
  const { slug } = params;
  const [loader, setLoader] = React.useState(true)


  React.useEffect(() => {
    if (slug) {
      getPaginasById();
    }
  }, [slug]);

  const getPaginasById = async () => {
    try {

      let url = ''
      let isById = Number.isInteger(Number(slug))
      if (isById)
        url = `paginas/${slug}?_locale=es&_publicationState=preview&eliminado=false`
      else
        url = `paginas?_slug=${slug}&_locale=es&_publicationState=preview&eliminado=false`

      const { data } = await fetch(url, 'get');
     
      setDataResult(isById ? data : data[0]);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {loader ?
        <DynamicCommonLoader dynamic={"225px"} /> : (
          <>
            {dataResult.template == 'template1' && <Template1Component children={dataResult} />}
            {dataResult.template == 'template2' && <Template2Component children={dataResult} />}
            {dataResult.template == 'template3' && <Template3Component children={dataResult} />}
          </>


        )}
    </>
  );
}
export default PageTemplate;

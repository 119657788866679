import "./template2.css";
import React from "react";
import dompurify from "dompurify";
import { mySort } from "../../../utils/general";

const Template2Component = ({ children }) => {
  const sanitizer = dompurify.sanitize;
  const youtubeUrlRegex =
    /^https?:\/\/(?:www\.)?youtube(?:-nocookie)?\.com\/embed\/([a-zA-Z0-9_-]{11})/;
  const [body, setBody] = React.useState();
  const [sidemenu, setSidemenu] = React.useState([]);

  const config = {
    WHITELIST: {
      iframe: ["src", "allowfullscreen", "frameborder"],
    },
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "autoplay", "target"],
  };

  const parser = new DOMParser();

  const allowIframe = (content) => {
    let clean = "";
    const doc = parser.parseFromString(content, "text/html");

    const iframes = doc.getElementsByTagName("iframe");

    for (let i = 0; i < iframes.length; i++) {
      const url = iframes[i].getAttribute("src");

      if (!url || !url.match(youtubeUrlRegex)) {
        // Si la URL no es de YouTube, se elimina el iframe
        iframes[i].parentNode.removeChild(iframes[i]);
      }
    }

    clean = dompurify.sanitize(doc.documentElement.innerHTML, config);
    return clean;
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0 });

    const sidemenu = [...children.template2.sidemenu];
    mySort(sidemenu, "order");

    setBody(sidemenu[0]);
    setSidemenu(sidemenu);
  }, [children]);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const sectionBackgroundImage = {
    background: `url("${children?.template2?.fondo?.url}")`,
    // backgroundSize: 'cover',
    backgroundColor: "#dedbd9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "center",
    height: "100%",
    backgroundAttachment: "fixed",
  };

  return (
    <section style={sectionBackgroundImage}>
      <div className="container-lg pb-5">
        <div className="row pt-5">
          <div className="col-md-3 col-lg-3">
            <div className="d-flex flex-column justify-content-center">
              {children.nombre.length < 14 ? (
                <h2 className="internacion__titulo2 mb-4">{children.nombre}</h2>
              ) : (
                <h2 className="internacion__titulo mb-4">{children.nombre}</h2>
              )}
              <div className="d-flex flex-column mb-4">
                {sidemenu.map((data, index) => {
                  return (
                    <button
                      type="button"
                      key={index}
                      className="btn grandiend-btn my-1"
                      onClick={() => setBody(data)}
                    >
                      {data.nombre}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-9 col-lg-9">
            {body && (
              <>
                <div className="card p-4 b-radius-10">
                  <h2 style={{ color: "#05294F" }}>{body.nombre}</h2>
                  <hr />
                  <div className="contenido">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: allowIframe(body.contenido),
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Template2Component;

import React, { useRef } from "react";
import Slider from "react-slick";
import LocationIcon from "../../../assets/img/location-pin.png";
import IconPrev from "../../../assets/img/icon-prev.png";
import IconNext from "../../../assets/img/icon-next.png";
import "./slide-centros-medicos.css";
import { fetch } from "../../../utils/fetch";
import CustomSelect from "../../custom-select";
import { GlobalContextMemorySpace } from "../../../context/globalContext";

const SliderCentrosMedicosComponent = () => {
  const [contenido, setContenido] = React.useState(null);
  const { CentrosMedicosSectionFetchData } = React.useContext(
    GlobalContextMemorySpace
  );
  // const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  // const [maxCardsPerScreen, setMaxCardsPerScreen] = React.useState(1);
  const sliderRef = useRef(null);

  React.useEffect(() => {
    // getContenido()
    setContenido(CentrosMedicosSectionFetchData?.centrosMedicos || []);
    const timer = setTimeout(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [CentrosMedicosSectionFetchData]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const bodyCards = document.querySelectorAll(".card-body-principal");
      let maxHeight = 0;

      bodyCards?.forEach((card) => {
        if (card.offsetHeight > maxHeight) {
          maxHeight = card.offsetHeight;
        }
      });

      bodyCards?.forEach((card) => {
        card.style = `height: ${maxHeight}px !important`;
      });

      if (bodyCards) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const parentRef = React.useRef(null);
  const [parentWidth, setParentWidth] = React.useState(0);
  const [maxCardsPerParent, setMaxCardsPerParent] = React.useState(1);

  React.useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    const calculateMaxCards = () => {
      const maxCardWidth = 320; // Ancho máximo de las tarjetas
      const newMaxCardsPerParent = Math.floor(parentWidth / maxCardWidth);
      setMaxCardsPerParent(newMaxCardsPerParent || 1);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Obtener el ancho inicial de la capa padre
    calculateMaxCards();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [parentWidth]);

  const settings = {
    slidesToShow: maxCardsPerParent,
    slidesToScroll: contenido?.length,
    centerMode: true,
    centerPadding: "0px",
    className: "slideCentrosMedicos",
    infinite: true,
    speed: 500,
    dots: false,
    autoPlay: true,
    arrows: contenido?.length > 4 ? true : false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipeToSlide: true,
  };
  const listSucursales =
    contenido &&
    contenido?.map((data, index) => {
      return {
        name: data.nombre,
        value: data.id,
      };
    });

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        src={IconPrev}
        style={{
          ...style,
          display: "block",
          width: "50%!important",
          height: "50%!important",
        }}
        onClick={onClick}
        onTouchStart={onClick}
        alt="prev-arrow"
      />
    );
  }

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        src={IconNext}
        style={{
          ...style,
          display: "block",
          width: "100% !important",
          height: "100% !important",
          // marginTop:"-5px",
          // marginRight:"-1px"
        }}
        onClick={onClick}
        onTouchStart={onClick}
        alt="next-arrow"
      />
    );
  }

  return (
    <div className="mt-3" ref={parentRef}>
      <CustomSelect listSucursales={listSucursales} />
      <Slider ref={sliderRef} {...settings}>
        {/* Card 1 */}
        {contenido &&
          contenido?.map((data, index) => {
            return (
              <div key={index} style={{ height: "100%" }}>
                <div
                  className="indice-centro"
                  key={index}
                  style={{ height: "100%" }}
                >
                  {/* Principal Card */}
                  <div
                    className="container-cards"
                    style={{ padding: "20px", height: "200%" }}
                  >
                    <div
                      className="card bg-transparent principal-card border-0 b-radius-10"
                      style={{ height: "200%" }}
                    >
                      {/* Imagen Aqui */}
                      <img
                        src={data?.imagenUrl}
                        alt=""
                        className="card-img-top card-img-principal"
                      />
                      <div className="card-body bg-white d-flex card-body-principal">
                        <div className="texto-card">
                          <span style={{ fontWeight: "bold" }}>
                            {data.nombre}
                          </span>
                          <br />
                          <img
                            src={LocationIcon}
                            alt="icon"
                            style={{
                              height: 17,
                              position: "absolute",
                              left: 10,
                              marginTop: 4,
                            }}
                          />
                          <span>{data.direccion}</span>
                          <br />
                          <span>{data.localidad}</span>
                          <br />
                          <span>{data.provincia}</span>
                          <br />
                        </div>
                      </div>
                    </div>
                    {/* Hover Card */}
                    <div className="card bg-transparent hover-card d-none border-0">
                      {/* Imagen Aqui */}
                      <div className="card-img-principal">
                        <div
                          dangerouslySetInnerHTML={{ __html: data.iframe_mapa }}
                        />
                      </div>

                      <div className="card-body bg-white d-flex justify-content-center align-items-center card-body-principal">
                        <div className="texto-card" style={{ padding: "12px" }}>
                          <a
                            href={"/como-llegar/" + data.id}
                            className="stretched-link"
                          >
                            ¿Cómo Llegar?
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default SliderCentrosMedicosComponent;

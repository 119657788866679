/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import dompurify from "dompurify";
import { useParams, Link, useNavigate } from "react-router-dom";
import { fetch } from "../../../utils/fetch";
import cruz from "../../../assets/img/cruz.png";
import DynamicCommonLoader from "../../../components/common-loader/dynamicCommonLoader";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "pageview",
    page: "Página dinámica de novedades",
  },
  dataLayerName: "PageDataLayer",
};

const VerNovedadesPage = () => {
  TagManager.dataLayer(tagManagerArgs);

  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  let { slug } = useParams();
  const navigate = useNavigate();

  const config = {
    WHITELIST: {
      iframe: ["src", "allowfullscreen", "frameborder"],
    },
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "autoplay", "target"],
  };

  const parser = new DOMParser();
  const youtubeUrlRegex =
    /^https?:\/\/(?:www\.)?youtube(?:-nocookie)?\.com\/embed\/([a-zA-Z0-9_-]{11})/;
  const allowIframe = (content) => {
    let clean = "";
    const doc = parser.parseFromString(content, "text/html");

    const iframes = doc.getElementsByTagName("iframe");

    for (let i = 0; i < iframes.length; i++) {
      const url = iframes[i].getAttribute("src");

      if (!url || !url.match(youtubeUrlRegex)) {
        // Si la URL no es de YouTube, se elimina el iframe
        iframes[i].parentNode.removeChild(iframes[i]);
      }
    }

    clean = dompurify.sanitize(doc.documentElement.innerHTML, config);
    return clean;
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      let url = "";
      let isById = Number.isInteger(Number(slug));
      if (isById)
        url = `novedades/${slug}?_locale=es&_publicationState=preview&eliminado=false`;
      else
        url = `novedades?_slug=${slug}&_locale=es&_publicationState=preview&eliminado=false`;

      const { data } = await fetch(url, "GET");

      setContenido(isById ? data : data[0]);
      setLoader(false);
    };
    getData();
  }, []);

  return (
    <>
      {loader ? (
        <DynamicCommonLoader dynamic={"225px"} />
      ) : (
        <section className="bg-gray">
          <div className="container">
            <div className="row pt-5 pb-5">
              <div className="col-lg-12">
                <div className="card card-como-llegar p-5 b-radius-10">
                  <div className="row">
                    {/* MV */}
                    <div className="d-block d-lg-none">
                      <div style={{ color: "#185a9a", fontSize: "16px" }}>
                        Novedades
                      </div>
                      <Link
                        to="/novedades"
                        style={{
                          color: "#185a9a",
                          textDecoration: "none",
                          fontSize: "16px",
                        }}
                      >
                        <b>Ver todas</b>
                      </Link>
                    </div>

                    {contenido.imagen != null && (
                      <div className="col-12 col-lg-5">
                        <br className="d-lg-none" />
                        <div
                          className="d-lg-block"
                          style={{ aspectRatio: "600/270" }}
                        >
                          <img
                            className="rounded"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={contenido?.imagen?.url}
                          />
                        </div>
                      </div>
                    )}

                    <div
                      className={
                        contenido?.destacado
                          ? "col-12 col-lg-7"
                          : "col-12 col-lg-7"
                      }
                    >
                      <div className="card-header bg-transparent border-0 d-flex justify-content-between p-0 py-3">
                        {/* PC */}
                        <div className="d-none d-lg-block">
                          <span style={{ color: "#185a9a", fontSize: "26px" }}>
                            Novedades |{" "}
                            <Link
                              to="/novedades"
                              style={{
                                color: "#185a9a",
                                textDecoration: "none",
                              }}
                            >
                              <b>Ver todas</b>
                            </Link>
                          </span>
                        </div>

                        <div
                          style={{ position: "absolute", top: 40, right: 40 }}
                        >
                          <img
                            onClick={() => navigate(-1)}
                            src={cruz}
                            alt="navigate"
                          />
                        </div>
                      </div>

                      <h2 className="title_reusable_components">
                        {contenido && contenido.titulo}
                      </h2>

                      {/* {contenido.imagen != null && !contenido?.destacado && (
                        <div
                          className="d-block d-lg-none"
                          style={{ aspectRatio: "600/270" }}
                        >
                          Hello 2
                          <img
                            className="rounded"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={contenido?.imagen?.url}
                          />
                        </div>
                      )} */}
                    </div>
                  </div>

                  <hr className="separador_reusable_components" />
                  <div className="contenido">
                    <small>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizer(
                            contenido && contenido.descripcionCorta
                          ),
                        }}
                      />
                    </small>
                    {/* <br />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(contenido && contenido.descripcion),
                      }}
                    /> */}
                    <br />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: allowIframe(contenido && contenido.descripcion),
                      }}
                    />

                    {/* {
                                            (contenido.imagen!=null && contenido?.destacado) && <div><img style={{ width: '100%' }} src={contenido?.imagen?.url} /></div>
                                        } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default VerNovedadesPage;

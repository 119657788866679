import React, { useEffect, useState } from "react";

const LatestYouTubeVideo = ({ dataSocial, getYouTubeVideoId }) => {
  const [videoId, setVideoId] = useState(null);
  const channelId = process.env.REACT_APP_YOTUBE_CHANNEL_ID;

  useEffect(() => {
    const fetchYouTubeFeed = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/social-networks/feed/${channelId}`
        );
        const text = await response.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, "application/xml");
        const latestVideoId =
          xml.getElementsByTagName("yt:videoId")[0].textContent;
        let source = `https://www.youtube.com/embed/${latestVideoId}`;
        setVideoId(source);
      } catch (error) {
        console.error("Error fetching the YouTube feed:", error);
      }
    };

    fetchYouTubeFeed();
  }, [channelId]);

  return (
    <div>
      {videoId ? (
        <iframe
          id="iframe-social1"
          width="560"
          height="315"
          src={videoId}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Latest YouTube Video"
          style={{ height: "100%" }}
          referrerpolicy="strict-origin-when-cross-origin"
        ></iframe>
      ) : (
        <iframe
          id="iframe-social1"
          src={getYouTubeVideoId(dataSocial?.url_video_youtube)}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          title="Vídeo para youtube"
          style={{ height: "100%" }}
        ></iframe>
      )}
    </div>
  );
};

export default LatestYouTubeVideo;

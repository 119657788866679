import React from "react";
import error404 from "../../assets/img/error-404.png";
import { Link } from "react-router-dom";
import "./not-found.css";

const NotFoundPage = () => {
  return (
    <div className="container-principal">
      <img src={error404} alt={`Error 404`} />
      <div className="texto404">
        <center>
          "Lo sentimos, la página que estás buscando no se encuentra disponible
          en este momento. <br />
          Por favor, verifica la URL o regresa a la página de inicio."
        </center>
      </div>
      <Link
        to={"/"}
        className="btn navItem__button--hb button-continuar"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Inicio
      </Link>
    </div>
  );
};
export default NotFoundPage;

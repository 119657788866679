import "./style.css";
import React, { useRef, useEffect } from "react";
import { fetch } from "../../../utils/fetch";
import { Modal, Row, Col } from "react-bootstrap";
import Arrow from "../../../assets/img/arrow_down.png";
import LatestYouTubeVideo from "./LatestYouTubeVideo";

const SocialNetworkComponent = () => {
  const [dataSocial, setDataSocial] = React.useState();
  const myRef = useRef(null);

  const executeScroll = () => scrollToRef(myRef);

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetBottom = ref.current.offsetTop + ref.current.offsetHeight;
      window.scrollTo({ top: offsetBottom });
    }
  };

  const getSocialNetwork = () => {
    fetch(`social-networks-home`, "GET")
      .then(({ data }) => {        
        if (data.social)
          {                                    
            setDataSocial(data.social);
          }
      })
      .catch((err) => {
        
      });
  };

  const getYouTubeVideoId = (url) => {
    const iframeRegex =
      /<iframe[^>]+src="(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/;
    const urlRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    let matches = url.match(iframeRegex);
    if (!matches) {
      matches = url.match(urlRegex);
    }

    return matches ? `https://www.youtube.com/embed/${matches[1]}` : url;
  };

  React.useEffect(() => {
    getSocialNetwork();
  }, []);

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }, [dataSocial]);

  return (
    <section className="section-social-network" ref={myRef}>
      <div className="container container-social-network-home">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-none d-lg-block">
              <div className="col section-novedades_titulo mb-4">
                <h2 className="pb-4" style={{ color: '#004990' }}>Redes sociales</h2>
              </div>
            </div>
            <div className="d-block d-lg-none">
              <div className="col section-social-movil_titulo">
                <h2 className="mt-4" style={{ color: '#004990' }}>Redes sociales</h2>
              </div>
            </div>
          </div>
        </div>

        {dataSocial?.url_video_youtube && (
          <div className="row">
            <div className="col-lg-6 contenedor-youtube">
              <div className="social-container-small border-0 w-100 h-80 shadow-lg tabletScreen">
                <LatestYouTubeVideo
                  dataSocial={dataSocial}
                  getYouTubeVideoId={getYouTubeVideoId}
                />
              </div>
              <div className="social-container-small border-0 w-100 h-90 shadow-lg tabletScreen">
              <iframe
                id="iframe-social1"
                src={getYouTubeVideoId(dataSocial?.url_video_youtube_estatico)}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                title="Vídeo para youtube"
                style={{ height: "100%" }}
              ></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-instagram">
              <div className="social-container-small-ig instagram-container border-0 w-90 shadow-lg tabletScreen">
                {dataSocial?.url_imagen_instagram && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataSocial.url_imagen_instagram,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SocialNetworkComponent;

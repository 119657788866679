/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./como-llegar.css";
import { fetch } from "../../utils/fetch";
import LocationIcon from "../../assets/img/location-pin.png";
import Phone from "../../assets/img/phone-como-llegar.png";
import Bus from "../../assets/img/icon-bus-como-llegar.png";
import Tren from "../../assets/img/tren-como-llegar.png";
import { useParams, Link } from "react-router-dom";
import { Procesando } from "../../components/comun/procesando";
import dompurify from "dompurify";
import DynamicCommonLoader from "../../components/common-loader/dynamicCommonLoader";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "pageview",
    page: "Como llegar",
  },
  dataLayerName: "PageDataLayer",
};

const ComoLlegarPage = () => {
  TagManager.dataLayer(tagManagerArgs);

  const { id } = useParams();
  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);
  const [dataCentroMedico, setDataCentroMedico] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const [btnSeleccionado, setBtnSeleccionado] = React.useState(id);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (id) {
      getDataById();
    }
  }, [id]);

  const getData = async () => {
    const { data } = await fetch(
      `centros-medicos?_locale=es&onOff=true&eliminado=false`,
      "GET"
    );
    setContenido(data);
    setLoader(false);
  };

  const getDataById = async () => {
    setLoader(true);
    setDataCentroMedico(null);
    const { data } = await fetch(`centros-medicos/${id}?_locale=es`, "GET");
    setDataCentroMedico(data);
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <DynamicCommonLoader dynamic={"225px"} />
      ) : (
        <section className={"bg-gray"}>
          <div className="container-lg pb-4 pt-4">
            <div className="row">
              <div>
                <h2 className="como_llegar__titulo mb-4 mt-4">¿Cómo Llego?</h2>
                <div className="d-md-block">
                  <div className="d-flex gap-3 mb-5 flex-column-mobile">
                    {contenido &&
                      contenido.map((data, index) => (
                        <Link
                          to={"/como-llegar/" + data.id}
                          type="button"
                          key={index}
                          onClick={() => setBtnSeleccionado(data.id)}
                          className={`btn navItem__button--hb btnCentroHB ${
                            btnSeleccionado == data.id && "btnSeleccionado"
                          }`}
                        >
                          {data.nombre}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-12 container-como-llegar">
                <div className="card card-como-llegar p-3 b-radius-10">
                  {dataCentroMedico ? (
                    <>
                      <h2 className="title_reusable_components">
                        {dataCentroMedico.nombre}
                      </h2>
                      {/* <hr className="separador_reusable_components"/> */}
                      <div className="contenido pb-3">
                        <div className="row">
                          <div className="col-lg-6 pe-4">
                            {/* Direccion */}
                            <div className="row mb-3">
                              <div className="col-1 p-0 text-center">
                                <img
                                  src={LocationIcon}
                                  alt="icon"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-11 p-0">
                                <span>
                                  <b>{dataCentroMedico.direccion}</b>
                                </span>
                                <br />
                                <span>{dataCentroMedico.localidad}</span>
                                <br />
                                <span>{dataCentroMedico.provincia}</span>
                                <br />
                              </div>
                            </div>
                            {/* Info Contacto */}
                            <div className="row mb-3">
                              <div className="col-1 p-0 text-center">
                                <img
                                  src={Phone}
                                  alt="icon"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-11 p-0">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizer(
                                      dataCentroMedico.info_contacto
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                            {/* Como LLego */}
                            <div className="row">
                              <div className="col-lg-12">
                                <p className="mb-1">
                                  <b>¿Cómo Llego?</b>
                                </p>
                              </div>
                            </div>
                            {/* Colectivos */}
                            <div className="row">
                              <div className="col-1 p-0 text-center">
                                <img
                                  src={Bus}
                                  alt="icon"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-11 p-0">
                                <span>
                                  Colectivos: {dataCentroMedico.colectivos}
                                </span>
                              </div>
                            </div>
                            {/* Tren */}
                            <div className="row">
                              <div className="col-1 p-0 text-center">
                                <img
                                  src={Tren}
                                  alt="icon"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-11 p-0">
                                <span>Tren: {dataCentroMedico.tren}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mt-2">
                            <div className="map-container">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: dataCentroMedico.iframe_mapa,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-4 col-lg-2">
                            <div className="row">
                              <Link
                                to={
                                  "/centros-medicos/ver/" + dataCentroMedico.id
                                }
                                className="btn btn-como-llegar-ver-mas"
                              >
                                Ver más
                              </Link>
                            </div>
                          </div>

                          {/* <div className='col-sm-12 text-center'>
                                                <Link to={'/centros-medicos/ver/' + dataCentroMedico.id} className="btn-como-llegar-ver-mas">Ver más</Link>
                                            </div> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <Procesando />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default ComoLlegarPage;

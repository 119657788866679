import React from "react";
import Celular from "../../../assets/img/celular.png";
import PlayStore from "../../../assets/img/btn-google-play.png";
import AppStore from "../../../assets/img/btn-appstore.png";
import { Link } from "react-router-dom";
import "./bajarr-app.css";
const BajarAppComponent = () => {
  return (
    <section className="section-bajarapp">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* Versión PC */}
            <div className="d-none d-lg-block">
              <div className="d-flex section-bajarapp__imagen">
                <img
                  src={Celular}
                  alt=""
                  className="img-fluid"
                  style={{ width: "246px", height: "300px" }}
                />
                <div className="d-flex section-bajarapp__texto">
                  <h3>Bajate nuestra app</h3>
                  <span>
                    Descargala de forma gratuita y gestioná tus turnos desde tu
                    celular
                  </span>
                  <div className="d-flex pt-4">
                    <a
                      href="https://apps.apple.com/ar/app/hospital-britanico/id1451864738?msclkid=ec8e4ed4cfab11ec90c72ad848881b4f"
                      target="_blank"
                    >
                      <img
                        src={AppStore}
                        alt=""
                        className="img-fluid"
                        style={{ width: "100px" }}
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.hbritanicoweb.app&hl=es_AR&gl=US"
                      target="_blank"
                      className="ms-3"
                    >
                      <img
                        src={PlayStore}
                        alt=""
                        className="img-fluid"
                        style={{ width: "100px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Versión Movil */}
            <div className="d-block d-lg-none">
              <div className="d-flex section-bajarapp__imagen justify-content-center">
                <img
                  src={Celular}
                  alt=""
                  className="img-fluid"
                  style={{ width: "246px", height: "300px" }}
                />
              </div>
              <div className="d-flex flex-column justify-content-center m-3">
                <h3 className="text-center">Bajate nuestra app</h3>
                <span className="text-center">
                  Descargala de forma gratuita y gestioná tus turnos desde tu
                  celular
                </span>
                <div className="text-center pt-4">
                  <div className="row">
                    <div className="col-12">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.hbritanicoweb.app&hl=es_AR&gl=US"
                        target="_blank"
                        className="me-1"
                      >
                        <img
                          src={PlayStore}
                          alt=""
                          className="img-fluid"
                          style={{ width: "100px" }}
                        />
                      </a>
                      <a
                        href="https://apps.apple.com/ar/app/hospital-britanico/id1451864738?msclkid=ec8e4ed4cfab11ec90c72ad848881b4f"
                        target="_blank"
                        className="ms-1"
                      >
                        <img
                          src={AppStore}
                          alt=""
                          className="img-fluid"
                          style={{ width: "100px" }}
                        />
                      </a>
                    </div>
                    {/* <div className='col-6'>
                      
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BajarAppComponent;

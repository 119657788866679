/* eslint-disable eqeqeq */
import "./slide.css";
import React, { useRef } from "react";
import { Nav } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { GlobalContextMemorySpace } from "../../../context/globalContext";
import IconPrev from "../../../assets/img/Chevron-left.svg";
import IconNext from "../../../assets/img/Chevron-right.svg";

const SliderComponent = () => {
  const [contenido, setContenido] = React.useState(null);
  const { SliderSectionFetchData } = React.useContext(GlobalContextMemorySpace);

  React.useEffect(() => {
    setContenido(SliderSectionFetchData || []);
  }, [SliderSectionFetchData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,// Flecha izquierda personalizada
    variableWidth: false,
  };



  return (
    <section>
      <div style={{ width: "calc(100vw - 17px)" }}>
        <Slider {...settings}>
          {contenido &&
            contenido.map((data) => (
              <div key={data.id}>
                {data.tipo == "VIDEO" && (
                  <div className="home-video">
                    {data.enlaceInterno ? (
                      <Link to={data.enlace}>
                        <video
                          className="home-video-imagen"
                          src={data.media.url}
                          autoPlay
                          loop
                          muted
                          playsInline
                        />
                      </Link>
                    ) : (
                      <a href={data.enlace} target="_blank">
                        <video
                          className="home-video-imagen"
                          src={data.media.url}
                          autoPlay
                          loop
                          muted
                          playsInline
                        />
                      </a>
                    )}

                    <div className="home-video-captions">
                      <div className="row">
                        <div className="col">
                          <h1 className="fondo-difuminado">
                            <b>{data.caption.h1}</b>
                          </h1>
                          <h3 className="fondo-difuminado">{data.caption.h3}</h3>
                          <div className="botones-grupo-video">
                            {data.caption.botones.map((btnData, index) => (
                              <Nav.Link href={btnData.url} target="_blank" key={index}>
                                <button
                                  type="button"
                                  className={`btn home-video-captions__btn${btnData.estilo}`}
                                >
                                  {btnData.nombre}
                                </button>
                              </Nav.Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {data.tipo == "IMAGEN" && (
                  <div className="home-video">
                    {data.enlaceInterno ? (
                      <Link to={data.enlace}>
                        <img
                          src={data.media.url}
                          className="home-video-imagen"
                          alt="imagen"
                        />
                      </Link>
                    ) : (
                      <a href={data.enlace} target="_blank">
                        <img
                          src={data.media.url}
                          className="home-video-imagen"
                          alt="imagen"
                        />
                      </a>
                    )}
                  </div>
                )}
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="arrow-hover"
      style={{
        ...style,
        position: "absolute",
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 10,
        cursor: "pointer",
      }}
      onClick={onClick}
      onTouchStart={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-chevron-left"
      >
        <polyline points="15 18 9 12 15 6" />
      </svg>
    </div>
  );
}

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="arrow-hover"
      style={{
        ...style,
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 10,
        cursor: "pointer",
      }}
      onClick={onClick}
      onTouchStart={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        fill="none"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-chevron-right"
      >
        <polyline points="9 18 15 12 9 6" />
      </svg>
    </div>
  );
}





export default SliderComponent;

/* eslint-disable react/jsx-filename-extension */
import React from "react";
import ReactGA from "react-ga4";
import { useRoutes, useLocation } from "react-router-dom";
import Layout from "./layout/default";
import CentrosMedicosPage from "./pages/centros-medicos";
import VerCentroMedico from "./pages/centros-medicos/ver";
import ComoLlegarPage from "./pages/como-llegar";
import ContactoPage from "./pages/contacto";
import HomePage from "./pages/home";
import NovedadesPage from "./pages/novedades";
import GalaPage from "./pages/gala";
import VerNovedadesPage from "./pages/novedades/detalle";
import VerEspecialidadesPage from "./pages/especialidades/detalle";
import ListaEspecialidades from "./pages/especialidades/lista";
import NotFoundPage from "./pages/not-found";
import PageTemplate from "./pages/templates";
import "./assets/fonts/fontawesome-pro/css/all.css";
import { GlobalContext } from "./context/globalContext";

import EspecialidadesPage from "pages/especialidades";
import PrensaPage from "pages/prensa";

if (process.env.REACT_APP_ENVIROMENT_DEBUG === "false") {
  ReactGA.initialize("G-9DP2DZEBMY");
}

// ReactGA.initialize("G-GHEEV8FTJL"); //De prueba

function App() {
  const location = useLocation();
  const redirects = {
    "/plandesalud/": "https://plandesalud.hospitalbritanico.org.ar/",
    "/planes/": "https://plandesalud.hospitalbritanico.org.ar/planes",
    "/plandesalud/planes/":
      "https://plandesalud.hospitalbritanico.org.ar/planes",
    "/healthplan/news/81c23d9b-0c39-44ef-ac13-29ef13decbd5/":
      "https://plandesalud.hospitalbritanico.org.ar/informacion-util",
    "/plandesalud/cartilla/":
      "https://plandesalud.hospitalbritanico.org.ar/cartilla",
    "/healthplan/news/6e8e98bb-5968-419b-9000-b1c94e7c476e/":
      "https://plandesalud.hospitalbritanico.org.ar/novedades/capacitacion-digital-para-adultos-mayores",
    "/plandesalud/informacion/":
      "https://plandesalud.hospitalbritanico.org.ar/formas-de-pagos-1",
    "/healthplan/news/361ca284-d4b2-4753-8fee-92b123d0f28e/":
      "https://plandesalud.hospitalbritanico.org.ar/asistencia-al-viajero-2",
    "/content/pdf/reglamento_pshb.pdf/":
      "https://sitioinstituionalqa.blob.core.windows.net/strapi-qa/strapi-qa/assets/Reglamento_PSHB_1306d37a6d.pdf",
  };

  React.useEffect(() => {
    localStorage.clear();
    // console.log(process.env)
    //TagManager.initialize({ gtmId: 'GTM-53XX5T9' });
  }, []);

  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });

    let path = location.pathname.toLocaleLowerCase();
    if (path[path.length - 1] !== "/") path += "/";

    if (Object.keys(redirects).indexOf(path) !== -1) {
      window.location.href = redirects[path];
      return;
    }
  }, [location]);

  const mainRoutes = {
    path: "/",
    element: (
      <GlobalContext>
        <Layout />
      </GlobalContext>
    ),
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/pages/:slug", element: <PageTemplate /> },
      { path: "/contacto", element: <ContactoPage /> },
      { path: "/novedades", element: <NovedadesPage /> },
      { path: "/gala-anual", element: <GalaPage /> },
      { path: "/novedades/:slug", element: <VerNovedadesPage /> },
      { path: "/prensas", element: <PrensaPage /> },
      { path: "/como-llegar", element: <ComoLlegarPage /> },
      { path: "/como-llegar/:id", element: <ComoLlegarPage /> },
      { path: "/centros-medicos", element: <CentrosMedicosPage /> },
      { path: "/centros-medicos/ver/:id", element: <VerCentroMedico /> },
      {
        path: "/centros-medicos-preview/ver/:id",
        element: <VerCentroMedico />,
      },
      { path: "/pages-preview/:slug", element: <PageTemplate /> },
      { path: "/novedades-preview/:slug", element: <VerNovedadesPage /> },
      {
        path: "/especialidades-preview/:slug",
        element: <VerEspecialidadesPage />,
      },
      { path: "/especialidades/:slug", element: <EspecialidadesPage /> },
      { path: "/especialidades", element: <ListaEspecialidades /> },
      { path: "*", element: <NotFoundPage /> },
    ],
  };

  const routing = useRoutes([mainRoutes]);
  return routing;
}

export default App;

import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

export default function CustomSelect({ listSucursales }) {
  useEffect(() => {
    for (const dropdown of document.querySelectorAll(
      ".custom-select-wrapper"
    )) {
      dropdown.addEventListener("click", function () {
        this.querySelector(".custom-select").classList.toggle("open");
        this.querySelector(".custom-select__trigger").classList.toggle(
          "quitar_bordes"
        );
      });
    }

    for (const option of document.querySelectorAll(".custom-option")) {
      option.addEventListener("click", (e) => {
        const currenElement = e.currentTarget;
        if (!currenElement.classList.contains("selected")) {
          currenElement.parentNode
            .querySelector(".custom-option.selected")
            .classList.remove("selected");
          currenElement.classList.add("selected");
          currenElement
            .closest(".custom-select")
            .querySelector(".custom-select__trigger span").textContent =
            currenElement.textContent;
        }
      });
    }

    const elements = document.getElementsByClassName("rootSelect");

    Array.from(elements).forEach(function (element) {
      element.addEventListener("click", (e) => {
        for (const select of document.querySelectorAll(".custom-select")) {
          if (!select.contains(e.target)) {
            select.classList.remove("open");
          }
        }
        for (const select of document.querySelectorAll(
          ".custom-select__trigger"
        )) {
          if (!select.contains(e.target)) {
            select.classList.remove("quitar_bordes");
            select.classList.add("redondear_bordes");
          }
        }
      });
    });
  }, []);

  return (
    <div className="col-12 col-md-5 col-lg-4 custom-select-wrapper">
      <div className="custom-select">
        <div className="custom-select__trigger">
          <span>Buscar sede</span>
          <div className="arrow"></div>
        </div>
        <div className="custom-options">
          {listSucursales
            ? listSucursales.map((item, idx, arr) => {
              const startIndex = idx === 0 ? "selected" : "";
              const lastIndex = arr.length - 1 === idx ? "lastDropDown" : "";
              return (                
                  <Link key={idx} to={"/como-llegar/" + item.value}>
                    <span
                      className={`custom-option  ${lastIndex}`}
                      data-value={item.value}
                    >
                      {item.name}
                    </span>
                  </Link>                
              );
            })
            : null}
        </div>
      </div>
    </div>
  );
}

import "./buscador-personalizado.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { fetch } from "../../utils/fetch";
import Cruz from "./../../assets/img/cruz.svg";
import { useNavigate } from "react-router-dom";

String.prototype.eliminarAcentos = function () {
  return this.replace("á", "a")
    .replace("é", "e")
    .replace("í", "i")
    .replace("ó", "o")
    .replace("ú", "u");
};

const BuscadorPersonalizado = ({ url, change, render, text }) => {
  const [clickeado, setClickeado] = React.useState(false);
  const [valor, setValor] = React.useState("");
  const [temporalData, setTemporalData] = React.useState([]);
  const [indice, setIndice] = React.useState(-1);
  const navigate = useNavigate();

  const seleccionar = (item) => {
    setClickeado(false);
    setTemporalData(temporalData.filter((row) => row.value == item.value));
    setValor("");
    change(item);
  };

  const procesar = (item) => {
    let inicio = item
      .toLowerCase()
      .eliminarAcentos()
      .indexOf(valor.toLocaleLowerCase().eliminarAcentos());
    if (inicio != -1) {
      let fin = inicio + valor.length;
      item = `${item.substring(0, inicio)}<b>${item.substring(
        inicio,
        fin
      )}</b>${item.substring(fin, item.length)}`;
    }

    return item;
  };

  const changeValue = async (e) => {
    const { value } = e.target;
    setValor(value);
    if (value.trim() == "") {
      setTemporalData([]);
      return;
    }

    let { data } = await fetch(url + value, "GET");
    data = data.map((item) => {
      if (render.value != "value") {
        item.value = item[render.value];
        delete item[render.value];
      }

      if (render.id != "id") {
        item.id = item[render.id];
        delete item[render.id];
      }

      return item;
    });
    setTemporalData(data.length > 0 ? [...data] : []);
  };

  const pressKey = (e) => {
    if (e.code === "ArrowDown") {
      let indiceTemporal = indice + 1;
      if (indiceTemporal >= temporalData.length) {
        setIndice(0);
      } else {
        setIndice(indiceTemporal);
      }
    } else if (e.code === "ArrowUp") {
      let indiceTemporal = indice - 1;
      if (indiceTemporal < 0) {
        setIndice(temporalData.length - 1);
      } else {
        setIndice(indiceTemporal);
      }
    } else if (e.code === "Enter") {
      if (temporalData.length > indice) {
        seleccionar(temporalData[indice]);
        setIndice(-1);
      }
    }
  };

  const mouseItem = (index) => {
    setIndice(index);
  };

  const cancelar = () => {
    setClickeado(!clickeado);
    setValor("");
  };

  return (
    <div className="w-100" onKeyUp={pressKey}>
      <div className="w-100" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: "1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!clickeado ? (
            <>
              <div
                className="contenedor-buscador-personalizado"
                onClick={() => setClickeado(!clickeado)}
              >
                {valor ? (
                  <div className="div-search">{valor}</div>
                ) : (
                  <div className="div-search" style={{ color: "gray" }}>
                    {text}
                  </div>
                )}
                <div className="opcion-buscar">
                  <FontAwesomeIcon icon={faSearch} color="white" />
                </div>
              </div>
              <button
                type="button"
                className="btn home-video-captions__btnestilo1 btn-vertodas-home"
                onClick={() => navigate("/especialidades")}
              >
                Ver todas
              </button>
            </>
          ) : (
            <>
              <div className="contenedor-buscador-personalizado contenedor-listado">
                <input
                  className="input-search"
                  value={valor}
                  autoFocus
                  onChange={(e) => changeValue(e)}
                />
                <div className="opcion-cancelar">
                  <img
                    src={Cruz}
                    onClick={() => cancelar()}
                    style={{ height: "18px" }}
                  />
                </div>

                {temporalData.length > 0 ? (
                  <div className="linea-divisora"></div>
                ) : (
                  <></>
                )}
                <div className="lista-opciones">
                  {temporalData.map((item, index) => {
                    return (
                      <div
                        onMouseOver={() => mouseItem(index)}
                        className={index == indice ? "item-seleccionado" : ""}
                        key={item}
                        onClick={() => seleccionar(item)}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: procesar(item.value),
                          }}
                        ></span>
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
              <button
                type="button"
                className="btn home-video-captions__btnestilo1 btn-vertodas-home"
                onClick={() => navigate("/especialidades")}
              >
                Ver todas
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuscadorPersonalizado;

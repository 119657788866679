import React from 'react';
import '../menus.css'
import Image1 from '../../../../assets/img/institucional.png'
import { Link } from 'react-router-dom';
import { mySortMenuItems } from '../../../../utils/general';

const MenuInstitucional = ({ display, subMenus, onHide }) => {

  React.useEffect(() => {
    if (subMenus?.columna1 && subMenus?.columna2) {
      mySortMenuItems(subMenus);
    }
  }, [subMenus])
  

  return (
    <div onMouseLeave={onHide}>
      {display &&

        <>
          <div className="custom__menu__container">
            <div className="custom__menu__item p-4">
              <div className="container w-75">
                <div className="row p-4">
                  {/* columna 1 */}
                  <div className="col-lg-4">
                    <img src={Image1} alt="image1" className="img-fluid" />
                  </div>
                  {/* columna 2 */}
                  <div className="col-lg-4">
                    <div className="custom__menu___list_item">
                      <ul>
                        {subMenus && subMenus.columna1.map((data, index) => {
                          return (
                            <li key={index}>                              
                               {data.enlaceInterno ?
                                <Link onClick={onHide} to={data.url}>{data.nombre}</Link>
                                :
                                <a onClick={onHide} href={data.url} target="_blank">{data.nombre}</a>
                              }
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  {/* columna 2 */}
                  <div className="col-lg-4">
                    <div className="custom__menu___list_item">
                      <ul>
                        {subMenus && subMenus.columna2.map((data, index) => {
                          return (
                            <li key={index}>                              
                               {data.enlaceInterno ?
                                <Link onClick={onHide} to={data.url}>{data.nombre}</Link>
                                :
                                <a onClick={onHide} href={data.url} target="_blank">{data.nombre}</a>
                              }
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="custom__menu__item_bg">

            </div>

          </div>

        </>

      }

    </div>
  );
}
export default MenuInstitucional;
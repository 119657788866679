/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import dompurify from "dompurify";
import { useParams } from "react-router-dom";
import { fetch } from "../../../utils/fetch";
import DynamicCommonLoader from "../../../components/common-loader/dynamicCommonLoader";
import TagManager from "react-gtm-module";
import BuscadorPersonalizado from "components/buscador-personalizado";
import "../especialidades.css";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Acordeon from "../acordeon";
import AvatarGenerico from "../../../assets/img/avatar_generico.jpg";

import { Modal, Row, Col } from "react-bootstrap";
import logo_HB from "../../../assets/img/logo_HB.png";

const tagManagerArgs = {
  dataLayer: {
    event: "pageview",
    page: "Página dinámica de novedades",
  },
  dataLayerName: "PageDataLayer",
};

const VerEspecialidadesPage = () => {
  TagManager.dataLayer(tagManagerArgs);
  const [modalVisible, setModalVisible] = useState(false);
  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const [urlVerMas, setUrlVerMas] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [columnaDrDestacado, setColumnaDrDestacado] = useState(8);

  let { slug } = useParams();

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    const getData = async () => {
      let url = "";
      url = `especialidades?_slug=${slug}&_publicationState=preview`;
      const { data } = await fetch(url, "GET");

      url = `doctores/destacado-especializacion/${data[0].id}`;
      const drDestacado = await fetch(url, "GET");
      //Hace que la columna de Quienes Somos tome todo el ancho de la pantalla si NO tiene Dr. Destacado
      if (!drDestacado.data[0]) {
        setColumnaDrDestacado(12);
      }

      url = `doctores/by-especializacion/${data[0].id}`;
      const byEspecializacion = await fetch(url, "GET");
      byEspecializacion.data.sort((a, b) => a.order - b.order);
      // Reordena el array para poner primero el objeto con "destacado" en true
      const sortedByDestacado = [...byEspecializacion.data].sort((a, b) =>
        a.destacado === b.destacado ? 0 : a.destacado ? -1 : 1
      );

      data[0].drdestacado = drDestacado.data[0];
      data[0].drByEspecializacion = sortedByDestacado;
      setContenido(data[0]);
      setLoader(false);
    };
    getData();
  }, []);

  const changeSearch = async (value) => {
    const { data } = await fetch("especialidades?_slug=" + value.slug, "GET");
    let url = `doctores/destacado-especializacion/${data[0].id}`;
    const drDestacado = await fetch(url, "GET");
    //Hace que la columna de Quienes Somos tome todo el ancho de la pantalla si NO tiene Dr. Destacado
    if (!drDestacado.data[0]) {
      setColumnaDrDestacado(12);
    }

    url = `doctores/by-especializacion/${data[0].id}`;
    const byEspecializacion = await fetch(url, "GET");
    byEspecializacion.data.sort((a, b) => a.order - b.order);
    // Reordena el array para poner primero el objeto con "destacado" en true
    const sortedByDestacado = [...byEspecializacion.data].sort((a, b) =>
      a.destacado === b.destacado ? 0 : a.destacado ? -1 : 1
    );

    data[0].drdestacado = drDestacado.data[0];
    data[0].drByEspecializacion = sortedByDestacado;

    setContenido(data[0]);
  };

  const cambiarTab = (numeroTab) => {
    if (activeTab !== numeroTab) {
      setActiveTab(numeroTab);
    }
  };

  return (
    <>
      {loader ? (
        <DynamicCommonLoader dynamic={"225px"} />
      ) : (
        <section className="bg-white">
          <div className="container">
            <div className="pt-3 mb-4 w-100 d-flex justify-content-center">
              <div className="container-buscador-esp">
                <BuscadorPersonalizado
                  url={"especialidades?_eliminado=false&titulo_contains="}
                  text={"Buscar especialidad"}
                  render={{ id: "id", value: "titulo" }}
                  change={changeSearch}
                />
              </div>
            </div>
            <div className="row pt-0 pb-5">
              <div className="col-lg-12">
                <div className="pt-5 pb-5 b-radius-10">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <h1
                        className="title_reusable_components"
                        style={{ paddingTop: "10px" }}
                      >
                        {contenido && contenido.nombre}
                      </h1>
                    </div>
                    <div className="col-12 col-md-4 button_turnos">
                      <div
                        className="d-flex flex-row justify-content-start align-items-start justify-content-md-end align-items-md-end h-100"
                        style={{ gap: "10px" }}
                      >
                        {contenido?.botones
                          ?.filter((boton) => boton.estado == "visible")
                          ?.map((boton, index) => {
                            return (
                              <a
                                href={
                                  boton.nombre === "Turnos" ? boton.url : null
                                }
                                onClick={
                                  boton.nombre !== "Turnos"
                                    ? () => {
                                        if(boton.mostrar_popup==="visible")
                                        {                                          
                                          setUrlVerMas(boton.url);
                                          setModalVisible(true);
                                        }else{
                                          //Acá abre la url porque no necesita mostrar el modal
                                          window.open(boton.url)
                                        }
                                      }
                                    : null
                                }
                                target="_blank"
                              >
                                <button
                                  type="button"
                                  className="btn navItem__button--plandesalud"
                                >
                                  {boton.nombre}
                                </button>
                              </a>
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <hr className="separador_reusable_components" />

                  <div>
                    <div className="scrollableNavDiv">
                      <Nav className="scrollableNav">
                        <NavItem>
                          <NavLink
                            className="botonSub"
                            onClick={() => cambiarTab("1")}
                          >
                            <div>
                              <button
                                type="button"
                                className={`btn navItem__button--quienessomos ${
                                  activeTab === "1"
                                    ? "activeTab baseTab"
                                    : "baseTab"
                                }`}
                              >
                                ¿Quienes somos?
                              </button>
                            </div>
                          </NavLink>
                        </NavItem>

                        {contenido?.show_equipo && (
                          <NavItem>
                            <NavLink
                              className="botonSub"
                              onClick={() => cambiarTab("2")}
                            >
                              <div>
                                <button
                                  type="button"
                                  className={`btn navItem__button--quienessomos ${
                                    activeTab === "2"
                                      ? "activeTab baseTab"
                                      : "baseTab"
                                  }`}
                                >
                                  Equipo
                                </button>
                              </div>
                            </NavLink>
                          </NavItem>
                        )}

                        {contenido?.show_subespecialidades && (
                          <NavItem>
                            <NavLink
                              className="botonSub"
                              onClick={() => cambiarTab("3")}
                            >
                              <div>
                                <button
                                  type="button"
                                  className={`btn navItem__button--quienessomos ${
                                    activeTab === "3"
                                      ? "activeTab baseTab"
                                      : "baseTab"
                                  }`}
                                >
                                  Sub-especialidades
                                </button>
                              </div>
                            </NavLink>
                          </NavItem>
                        )}

                        {contenido?.show_practicas && (
                          <NavItem>
                            <NavLink
                              className="botonSub"
                              onClick={() => cambiarTab("4")}
                            >
                              <div>
                                <button
                                  type="button"
                                  className={`btn navItem__button--quienessomos ${
                                    activeTab === "4"
                                      ? "activeTab baseTab"
                                      : "baseTab"
                                  }`}
                                >
                                  Prácticas
                                </button>
                              </div>
                            </NavLink>
                          </NavItem>
                        )} 
                      </Nav>
                    </div>

                    <TabContent
                      activeTab={activeTab}
                      style={{ paddingTop: "50px" }}
                    >
                      <TabPane tabId="1">
                        <div className="row">
                          {contenido?.drdestacado && (
                            <div className="col-12 card-doctor d-lg-none card-doctor-small">
                              <div>
                                <img
                                  className="picture"
                                  src={
                                    contenido.drdestacado.avatar
                                      ? contenido.drdestacado.avatar
                                      : AvatarGenerico
                                  }
                                />
                              </div>
                              <div className="data">
                                <p className="nombre-doctor">
                                  {contenido.drdestacado.nombres}
                                </p>
                                <p className="rol-doctor">
                                  {contenido.drdestacado.puesto}
                                </p>
                                <p>{contenido.drdestacado.especializacion}</p>
                              </div>
                            </div>
                          )}

                          <div className={`col-lg-${columnaDrDestacado}`}>
                            <div className="contenido">
                              <br className="card-doctor-large" />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: sanitizer(
                                    contenido && contenido.descripcion
                                  ),
                                }}
                              />
                            </div>
                          </div>

                          {contenido?.drdestacado && (
                            <div className="col-lg-4 card-doctor card-doctor-large">
                              <div>
                                <img
                                  className="picture"
                                  src={
                                    contenido.drdestacado.avatar
                                      ? contenido.drdestacado.avatar
                                      : AvatarGenerico
                                  }
                                />
                              </div>
                              <div className="data">
                                <p className="nombre-doctor">
                                  {contenido.drdestacado.nombres}
                                </p>
                                <p className="rol-doctor">
                                  {contenido.drdestacado.puesto}
                                </p>
                                <p>{contenido.drdestacado.especializacion}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </TabPane>

                      <TabPane tabId="2">
                        <div className="container" style={{ padding: 0 }}>
                          <br />
                          <div className="col-12">
                            <div className="sub-especialidad">
                              {contenido?.descripcion_equipo && (
                                <>
                                  <div className="descripcion-equipo">
                                    {contenido.descripcion_equipo}
                                  </div>
                                  <div className="blueLineStyle descripcion-equipo" />
                                </>
                              )}

                              <div className="row cards-equipo">
                                {contenido?.drByEspecializacion &&
                                  contenido.drByEspecializacion.map(
                                    (doctor, index) => (
                                      <div
                                        className="col-12 col-lg-4 descripcion-equipo"
                                        key={index}
                                      >
                                        <div className="cards-equipo">
                                          <div>
                                            <img
                                              className="picture"
                                              src={
                                                doctor?.avatar?.url
                                                  ? doctor.avatar.url
                                                  : AvatarGenerico
                                              }
                                              alt={`Doctor ${index + 1}`}
                                            />
                                          </div>
                                          <div className="data">
                                            <p className="nombre-doctor">
                                              {doctor.nombres}
                                            </p>
                                            <p className="rol-doctor">
                                              {doctor.puesto}
                                            </p>
                                            <p>{doctor.especializacion}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="3">
                        <div className="container">
                          <br />
                          <div className="col-12">
                            <div className="sub-especialidad">
                              {contenido?.titulo_subespecialidad && (
                                <>
                                  <p className="nombre-doctor titulo-subEspecialidad">
                                    {contenido.titulo_subespecialidad}
                                  </p>
                                  <div className="blueLineStyle titulo-subEspecialidad" />
                                </>
                              )}
                              {contenido?.desc_subespecialidad && (
                                <p className="titulo-subEspecialidad">
                                  {contenido.desc_subespecialidad}
                                </p>
                              )}
                            </div>
                            <div>
                              <div className="accordion">
                                {contenido?.sub_especialidades.map(
                                  ({ nombre, subespecialidad_item }) => (
                                    <Acordeon
                                      title={nombre}
                                      content={subespecialidad_item}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="4">
                        <div className="container">
                          <br />
                          <div className="col-12">                            
                            <div>
                              <div className="accordion">                                
                                {contenido?.practicas?.map(
                                  ({ titulo, descripcion }) => (
                                    <Acordeon
                                      title={titulo}
                                      content={[{"nombre":descripcion}]}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Modal size={"md"} show={modalVisible} centered className="modalClass">
        <Modal.Body>
          <div className="header-familygroup-information mt-2">
            <center>
              <img src={logo_HB} alt={`Logo HB`} />
            </center>
            <span className="titulo-eliminar-familiar">
              <center>
                Usted esta saliendo de la web del Hospital Británico
              </center>
            </span>
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div className="barra-eliminar-familiar"></div>
          </div>
          <div className="politica-proteccion">
            <div className="section-edit-information">
              <section>
                <Row>
                  <Col md={12}>
                    <div className="row mt-4">
                      <div
                        className="col-md-12 family-add-buttons"
                        style={{ paddingBottom: "10px" }}
                      >
                        <center>
                          <div
                            className="btn btn-hb-secondary-without-border"
                            onClick={() => setModalVisible(false)}
                          >
                            Cancelar
                          </div>
                          <a
                            className="btn btn-hb-primary-without-border"
                            href={urlVerMas}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => setModalVisible(false)}
                          >
                            Aceptar
                          </a>
                        </center>
                      </div>
                    </div>
                  </Col>
                </Row>
              </section>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default VerEspecialidadesPage;

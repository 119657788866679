import React from "react";
import "./template3.css";
import dompurify from "dompurify";

const Template3Component = ({ children }) => {
  const [content, setContent] = React.useState("");
  const [showImg, setShowImg] = React.useState(false);
  const youtubeUrlRegex = /^https?:\/\/(?:www\.)?youtube(?:-nocookie)?\.com\/embed\/([a-zA-Z0-9_-]{11})/;

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
    const config = {
      WHITELIST: {
        iframe: ["src", "allowfullscreen", "frameborder"],
      },
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["allow", "autoplay"],
    };

    const parser = new DOMParser();
    let clean = "";
    const doc = parser.parseFromString(
      children.template3.contenido,
      "text/html"
    );

    const iframes = doc.getElementsByTagName("iframe");

    for (let i = 0; i < iframes.length; i++) {
      const url = iframes[i].getAttribute("src");

      if (!url || !url.match(youtubeUrlRegex)) {
        // Si la URL no es de YouTube, se elimina el iframe
        iframes[i].parentNode.removeChild(iframes[i]);
      }
    }

    clean = dompurify.sanitize(doc.documentElement.innerHTML, config);
    setContent(clean);

    setShowImg(!!children.template3?.imagen);
  }, []);

  const sanitizer = dompurify.sanitize;
  return (
    <section className={"bg-white"} style={{ paddingBottom: "10%" }}>
      <div className="container-fluid">
        <div className={`row ${!showImg && "justify-content-center"}`}>
          {showImg && (
            <div className="col-md-3 col-lg-3 p-0">
              <div className="d-none d-md-block">
                <div className="d-flex flex-column mt-5">
                  <img
                    src={children.template3?.imagen?.url}
                    alt="children"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          )}
          <div className={`col-12 col-md-9 col-lg-9 d-flex ${!showImg && "justify-content-center"}`}>
            <div className="card border-0">
              <div className="card-body">
                <h2 className="title_reusable_components mt-5">
                  {children.template3.titulo}
                </h2>
                <hr className="separador_reusable_components" />
                <div className="contenido">
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
                <div className="d-block d-md-none">
                  {showImg && (
                    <img
                      src={children.template3?.imagen?.url}
                      alt="children"
                      className="img-fluid"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Template3Component;

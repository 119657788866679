import React, { useRef } from "react";
import "./estudia.css";
import { Link } from "react-router-dom";
import { GlobalContextMemorySpace } from "../../../context/globalContext";

const EstudiaComponent = () => {
  const [contenido, setContenido] = React.useState(null);
  const { EstudiosSectionFetchData } = React.useContext(
    GlobalContextMemorySpace
  );

  React.useEffect(() => {
    // getContenido()
    setContenido(EstudiosSectionFetchData && EstudiosSectionFetchData[0]);
  }, [EstudiosSectionFetchData]);

  // const getContenido = async () => {
  //   const { data } = await fetch(`seccion-home-estudias?_locale=es`, 'GET');
  //   console.log("ESTUDIA EN HB", data)
  //   setContenido(data[0])
  // }

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const scrollToRef = (ref) => {
    if (ref.current) {
      const offsetBottom = ref.current.offsetTop + ref.current.offsetHeight;
      window.scrollTo({ top: offsetBottom });
    }
  };

  return (
    <>
      {contenido && (
        <>
          <section className="section-estudia" ref={myRef}>
            <div className="container container-estudia">
              <div className="row">
                <div className="col-lg-6 pt-5">
                    <h2 className="section-estudia_titulo" style={{fontSize: "50px"}}>{contenido.titulo}</h2>
                    <p className="section-estudia_texto">
                      <div className="mt-5 mb-5 descripcion-texto"
                        dangerouslySetInnerHTML={{
                          __html: contenido.descripcion,
                        }}
                      />

                      {contenido.boton.urlInterna ? (
                        <Link
                          to={contenido.boton.url}
                          className="section-estudia_boton_info"
                        >
                          {contenido.boton.nombre}
                        </Link>
                      ) : (
                        <a
                          href={contenido.boton.url}
                          className="section-estudia_boton_info"
                          target="_blank"
                        >
                          {contenido.boton.nombre}
                        </a>
                      )}
                    </p>
                    <br />
                </div>
                <div className="col-lg-6 pt-5">
                  <img
                    className="img-fluid b-radius-10 imagen-estudia"
                    src={contenido?.foto[0]?.url}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
export default EstudiaComponent;

import React from 'react';
import '../menus.css'
import Image1 from '../../../../assets/img/docencia-investigacion.png'
import Image2 from '../../../../assets/img/logo-uba.png'
import Image3 from '../../../../assets/img/logo-uca.png'
import Isologotipo from '../../../../assets/img/logo-hb-blanco.png';
import { Link } from 'react-router-dom';
import { mySortMenuItems } from '../../../../utils/general';

const MenuDocencia = ({ display, subMenus, onHide }) => {

  React.useEffect(() => {
    if (subMenus?.columna1 && subMenus?.columna2) {
      mySortMenuItems(subMenus);
    }
  }, [subMenus])

  return (
    <div onMouseLeave={onHide}>
      {display &&

        <>
          <div className="custom__menu__container">
            <div className="custom__menu__item p-4">
              <div className="container w-75">
                <div className="row p-4">

                  {/* Convenios */}
                  <div className="col-lg-4">
                    <h4 className='text-white'>Convenios</h4>
                    <div>
                      <a href='https://www.uba.ar/#/' target="_blank">
                        <img src={Image2} alt="" className="img-fluid" />
                      </a>
                      <span className='text-white mx-2'>&</span>
                      <a href='https://www.hospitalbritanico.org.ar/' target="_blank">
                        <img src={Isologotipo} className="img-fluid" alt="" />
                      </a>
                    </div>
                    <div>
                      <a href='https://uca.edu.ar/es/home' target="_blank">
                        <img src={Image3} alt="" className="img-fluid" />
                      </a>
                      <span className='text-white mx-2'>&</span>
                      <a href='https://www.hospitalbritanico.org.ar/' target="_blank">
                        <img src={Isologotipo} className="img-fluid" alt="" />
                      </a>
                    </div>
                  </div>

                  {/* Imagen, se muestra si nay datos en columna 2 */}
                  {subMenus?.columna2?.length === 0 &&
                    <div className="col-lg-4">
                      <img src={Image1} alt="image1" className="img-fluid" />
                    </div>}

                  {/* Columna 1 de opciones de menú */}
                  <div className="col-lg-4">
                    <div className="custom__menu___list_item">
                      <ul>
                        {subMenus && subMenus.columna1.map((data, index) => {
                          return (
                            <li key={index}>
                              {data.enlaceInterno ?
                                <Link onClick={onHide} to={data.url}>{data.nombre}</Link>
                                :
                                <a onClick={onHide} href={data.url} target="_blank">{data.nombre}</a>
                              }
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                  {/* Columna 2 de opciones de menú */}
                  <div className="col-lg-4">
                    <div className="custom__menu___list_item">
                      <ul>
                        {subMenus && subMenus.columna2.map((data, index) => {
                          return (
                            <li key={index}>
                              {data.enlaceInterno ?
                                <Link onClick={onHide} to={data.url}>{data.nombre}</Link>
                                :
                                <a onClick={onHide} href={data.url} target="_blank">{data.nombre}</a>
                              }
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="custom__menu__item_bg">

            </div>

          </div>

        </>

      }

    </div>
  );
}
export default MenuDocencia;
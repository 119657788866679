import React from "react";
import Logo1 from "../../../assets/img/logos/logo1.jpg";
import Logo2 from "../../../assets/img/logos/logo2.jpg";
import Logo4 from "../../../assets/img/logos/logo4.jpg";
import Logo5 from "../../../assets/img/logos/logo5.jpg";
import Logo6 from "../../../assets/img/logos/logo6.png";
import "./style.css";

const LogosComponent = () => {
  return (
    <section className="section-logos bg-white">
      <div className="container">
        <div className="row pt-3 pb-3 pr-3 d-flex justify-content-center align-items-center">
          <div className="col-4 col-md text-center">
            <a
              href="http://qr.afip.gob.ar/?qr=e7rTZqrC85H6Ftzi3bXXGw,,"
              target="_blank"
            >
              <img src={Logo1} alt className="logosFooter" />
            </a>
          </div>
          <div className="col-4 col-md text-center">
            <a
              href="https://www.argentina.gob.ar/aaip/datospersonales"
              target="_blank"
            >
              <img src={Logo2} alt className="logosFooter" />
            </a>
          </div>
          <div className="col-4 col-md text-center">
            <a href="https://hospitalesporlasaludambiental.org" target="_blank">
              <img src={Logo4} alt className="logosFooter" />
            </a>
          </div>
          <div className="col-4 col-md text-center">
            <img src={Logo5} alt className="logosFooter" />
          </div>
          <div className="col-4 col-md text-center">
            <a href="https://www.argentina.tur.ar/#!/ar/information/sello-safe-travels-argentina">
              <img src={Logo6} alt className="logosFooter" id="safe-travel" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LogosComponent;

import React,{useState,useContext} from 'react';
import { Outlet } from "react-router-dom";
import FooterComponent from '../../components/footer';
import NavbarComponent from '../../components/navbar';
import {GlobalContextMemorySpace} from "../../context/globalContext";
import Loader from '../../components/loader';
import ErrorBoundary from 'components/exception/error';


const Layout = ({ children }) => {

    const [loader, setLoader] = useState(false)

    const {MainLoader, showLoaderHomePage} = useContext(GlobalContextMemorySpace);

    setTimeout(() => {
        setLoader(false)
        //window.scrollTo(0, 0);
    }, 2000);

    return (
        <>
            {showLoaderHomePage  ? 
            
            <Loader />
            
            : 
            
            <div id="plantilla-principal">
                {/* Navbar */}
                <NavbarComponent id="header-principal"/>

                {/* Body */}
                {/* <ErrorBoundary> */}
                    <main id="main-principal">
                        <Outlet />
                        {children}
                    </main>
                {/* </ErrorBoundary> */}

                {/* Footer */}
                <FooterComponent id="footer-principal"/>
            </div>
            }
            
            
        </>
    );
}
export default Layout;
import React from "react";
import dompurify from "dompurify";
import { Link } from "react-router-dom";
import { fetch } from "../../utils/fetch";
import LocationIcon from "../../assets/img/location-pin.png";
import Phone from "../../assets/img/phone-como-llegar.png";
import ArrowBlue from "../../assets/img/arrow_blue.png";
import "./centros-medicos.css";
import DynamicCommonLoader from "../../components/common-loader/dynamicCommonLoader";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "pageview",
    page: "Centros médicos",
  },
  dataLayerName: "PageDataLayer",
};

const CentrosMedicosPage = () => {
  TagManager.dataLayer(tagManagerArgs);

  const sanitizer = dompurify.sanitize;
  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const { data } = await fetch(
      `centros-medicos?onOff=true&_locale=es&eliminado=false&_sort=orden:ASC`,
      "GET"
    );
    setContenido(data);
    setLoader(false);
  };

  // d-none d-lg-block
  return (
    <>
      {loader ? (
        <DynamicCommonLoader dynamic={"225px"} />
      ) : (
        <section className="bg-gray">
          <div className="container-lg">
            <div className="row pt-5">
              <div>
                <h2 className="como_llegar__titulo mb-4 mt-4">
                  Red de Centros
                </h2>
                <div className="d-md-block">
                  <div className="d-flex gap-3 mb-5 flex-column-mobile">
                    {contenido &&
                      contenido.map((data, index) => (
                        <Link
                          to={"ver/" + data.id}
                          type="button"
                          key={index}
                          className="btn navItem__button--hb btnCentroHB"
                        >
                          {data.nombre}
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-12 container-centros">
                <div className="row">
                  {contenido &&
                    contenido.map((data, index) => {
                      return (
                        <div className="col-md-6 col-lg-6" key={index}>
                          <div className="card card-centros-medicos b-radius-20 mb-4 p-2">
                            <div className="card-body card-body2">
                              <div className="card-foto b-radius-0">
                                <img
                                  src={data?.imagen?.url}
                                  className="w-100 foto-centro"
                                  alt="..."
                                />
                              </div>
                              {/* <hr className="separador_reusable_components"/> */}
                              <h5 className="card-title mt-2">{data.nombre}</h5>

                              <p className="card-text row">
                                <div className="col-1">
                                  <img src={LocationIcon} alt="location" />
                                </div>
                                <div className="col-11">
                                  <b>{data.direccion}</b>
                                  <br />
                                  {data.localidad}
                                </div>
                              </p>
                              <p className="card-text row">
                                <div className="col-1">
                                  <img src={Phone} alt="phone" />
                                </div>
                                <div className="col-11">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizer(data.info_contacto),
                                    }}
                                  />
                                </div>
                              </p>
                            </div>
                            <div className="card-footer d-flex justify-content-end bg-transparent border-0">
                              <Link
                                to={"ver/" + data.id}
                                className="stretched-link"
                              >
                                <img src={ArrowBlue} alt="arrow blue" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default CentrosMedicosPage;

import { createContext, useState, useEffect } from "react";
import { fetch } from "../utils/fetch";
// memory space created
export const GlobalContextMemorySpace = createContext();


export const GlobalContext = ({children}) =>{

    const [lang, setLang] = useState('es');
    const [showLoaderHomePage, setShowLoaderHomePage] = useState(true)
    const [MainLoader, setMainLoader] = useState(true);
    const [NavBarSectionFetchData, setNavBarSectionFetchData] = useState(); 
    const [SliderSectionFetchData, setSliderSectionFetchData] = useState(); 
    const [WhatsappSectionFetchData, setWhatsappSectionFetchData] = useState();
    const [NovedadesSectionFetchData, setNovedadesSectionFetchData] = useState();
    const [NuestrosAccesosSectionFetchData, setNuestrosAccesosSectionFetchData] = useState();
    const [EstudiosSectionFetchData, setEstudiosSectionFetchData] = useState();
    const [CentrosMedicosSectionFetchData, setCentrosMedicosSectionFetchData] = useState();
        
    
    
   
    /* CONTENIDO NAVBAR */
    const NavBarSectionGetContenido = async () => {
        try {
            const { data } = await fetch(`menus?_locale=${lang}&onOff=true&eliminado=false&_sort=orden:ASC`, 'GET')
            return data;

        } 
        catch (error) {
            
            return {error: error};

        }
    };

     /* CONTENIDO HOME SLIDER */
     const HomeSliderGetContenido = async () => {
        try {
            const { data } = await fetch(`home-slides-home`, 'GET')            
            return data?.slides;

        } 
        catch (error) {
            
            return {error: error};

        }
    };

    /* CONTENIDO WHATSAPP */
    const WhatsappGetContenido = async () => {
        try {
            const { data } = await fetch(`seccion-home-whatsapps?`, 'GET')
            return data;

        } 
        catch (error) {
            
            return {error: error};

        }
    };


    /* CONTENIDO NOVEDADES */
    const NovedadesGetContenido = async () => {
        try {
            //const { data } = await fetch(`novedades?_locale=es&onOff=true&eliminado=false&_sort=destacado:DESC,created_at:DESC`, 'GET')
            const { data } = await fetch(`novedades-home`, 'GET')
            return data;

        } 
        catch (error) {
            
            return {error: error};

        }
    };

    /* CONTENIDO NUESTROS ACCESOS */
    const NuestrosAccesosGetContenido = async () => {
        try {
            const { data } = await fetch(`nuestros-accesos-home`, 'GET')
            return data.nuestrosAccesos;

        } 
        catch (error) {
            
            return {error: error};

        }
    };

    /* CONTENIDO ESTUDIOS */
    const EstudiosGetContenido = async () => {
        try {
            const { data } = await fetch(`seccion-home-estudias?_locale=es`, 'GET')
            return data;

        } 
        catch (error) {
            
            return {error: error};

        }
    };

    /* CONTENIDO ESTUDIOS */
    const CentrosMedicosGetContenido = async () => {
        try {
            const { data } = await fetch(`centros-medicos-home`, 'GET')
            return data;

        } 
        catch (error) {
            
            return {error: error};

        }
    };


    

    /* TURN OFF LOADER */
    const checkFetch = () => {

        //console.log("VA A CHEQUEAR");
        if(!NavBarSectionFetchData && !SliderSectionFetchData && !WhatsappSectionFetchData && !NovedadesSectionFetchData && !NuestrosAccesosSectionFetchData && !EstudiosSectionFetchData){
            //console.log("CHEQUEOOOOOOOOOOOOOO");
            setTimeout(() => {
                setShowLoaderHomePage(false);                
            }, 1000);            
        }

    }

    useEffect(()=>{
        //Mount: 
        NavBarSectionGetContenido().then((res)=>{setNavBarSectionFetchData(res)}).finally(()=>{
            checkFetch();
        }); 
        
        HomeSliderGetContenido().then((res)=>{setSliderSectionFetchData(res)}).finally(()=>{
            checkFetch();
        }); 

        // WhatsappGetContenido().then((res)=>{setWhatsappSectionFetchData(res)}).finally(()=>{
        //     checkFetch();
        // }); 

        NovedadesGetContenido().then((res)=>{setNovedadesSectionFetchData(res)}).finally(()=>{
            checkFetch();
        }); 

        NuestrosAccesosGetContenido().then((res)=>{setNuestrosAccesosSectionFetchData(res)}).finally(()=>{
            checkFetch();
        }); 

        EstudiosGetContenido().then((res)=>{setEstudiosSectionFetchData(res)}).finally(()=>{
            checkFetch();
        }); 

        CentrosMedicosGetContenido().then((res)=>{setCentrosMedicosSectionFetchData(res)}).finally(()=>{
            checkFetch();
        }); 


        return () =>{
        //Unmount
    
        }
      }, [])


    return(
        <GlobalContextMemorySpace.Provider 
            // @ts-ignore
            value={
                {
                    NavBarSectionFetchData,
                    checkFetch, 
                    showLoaderHomePage, 
                    setShowLoaderHomePage,
                    SliderSectionFetchData,
                    WhatsappSectionFetchData,
                    NovedadesSectionFetchData,
                    NuestrosAccesosSectionFetchData,
                    EstudiosSectionFetchData,
                    CentrosMedicosSectionFetchData,
                    MainLoader,
                    setMainLoader
                }}>
            {children}
        </GlobalContextMemorySpace.Provider>
    );
}
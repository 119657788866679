import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetch } from "../../../utils/fetch";
import DynamicCommonLoader from "components/common-loader/dynamicCommonLoader";

const initSize = window.matchMedia("(max-width: 1000px)").matches;
const maxByColumn = 15;

const ListaEspecialidades = () => {
  const [isMobile, setIsMobile] = useState(initSize);
  const [especialidades, setEspecialidades] = useState([]);
  const [especialidadesA, setEspecialidadesA] = useState([]);
  const [especialidadesB, setEspecialidadesB] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    window
      .matchMedia("(max-width: 1000px)")
      .addEventListener("change", (e) => setIsMobile(e.matches));
    getData();
  }, []);

  useEffect(() => {
    if (especialidades?.length > maxByColumn) {
      const length = especialidades.length;
      let midpoint = Math.floor(length / 2);

      if (length % 2 != 0) midpoint++;

      const first = especialidades.slice(0, midpoint);
      const second = especialidades.slice(midpoint);

      setEspecialidadesA(first);
      setEspecialidadesB(second);
    }
  }, [especialidades]);

  const getData = async () => {
    const { data } = await fetch(
      "especialidades?_eliminado=false&onOff=true&_sort=titulo:ASC",
      "GET"
    );

    setEspecialidades(data);
    setLoader(false);
  };

  const handleRedirect = (url) => {
    if (!url) return;
    navigate(url);
  };

  return (
    <>
      {loader ? (
        <DynamicCommonLoader dynamic={"225px"} />
      ) : (
        <section className="bg-white">
          <div className="container">
            <div className="row pt-0 pb-5">
              <div className="col-lg-12">
                <div className="pt-5 pb-5 b-radius-10">
                  <div className="row">
                    <div className="col-12 col-md-8">
                      <h1
                        className="title_reusable_components"
                        style={{ paddingTop: "10px" }}
                      >
                        Especialidades
                      </h1>
                    </div>
                  </div>

                  <hr className="separador_reusable_components" />
                  <div className="row">
                    {isMobile && (
                      <div class="col-xs-12">
                        {especialidades.map((d) => (
                          <div key={d.id} className="contenido mt-2">
                            <span
                              style={{
                                fontSize: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => handleRedirect(d.slug)}
                            >
                              &#8594; {d.nombre}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}

                    {!isMobile && (
                      <>
                        {especialidades?.length > maxByColumn ? (
                          <>
                            <div class="col-md-6">
                              {especialidadesA.map((d) => (
                                <div key={d.id} className="contenido">
                                  <span
                                    style={{
                                      fontSize: 24,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleRedirect(d.slug)}
                                  >
                                    &#8594; {d.nombre}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <div class="col-md-6">
                              {especialidadesB.map((d) => (
                                <div key={d.id} className="contenido">
                                  <span
                                    style={{
                                      fontSize: 24,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleRedirect(d.slug)}
                                  >
                                    &#8594; {d.nombre}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div class="col-md-6">
                            {especialidades.map((d) => (
                              <div key={d.id} className="contenido">
                                <span
                                  style={{
                                    fontSize: 24,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleRedirect(d.slug)}
                                >
                                  &#8594; {d.nombre}
                                </span>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ListaEspecialidades;

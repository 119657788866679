import React, { useState } from "react";
import "./template1.css";
import dompurify from "dompurify";

const Template1Component = ({ children }) => {
  const sanitizer = dompurify.sanitize;
  const [content, setContent] = useState("");
  const [showImg, setShowImg] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const youtubeUrlRegex =
    /^https?:\/\/(?:www\.)?youtube(?:-nocookie)?\.com\/embed\/([a-zA-Z0-9_-]{11})/;

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });

    const config = {
      WHITELIST: {
        iframe: ["src", "allowfullscreen", "frameborder"],
      },
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["allow", "autoplay"],
    };

    const parser = new DOMParser();
    let clean = "";
    const doc = parser.parseFromString(
      children.template1.contenido,
      "text/html"
    );

    const iframes = doc.getElementsByTagName("iframe");

    for (let i = 0; i < iframes.length; i++) {
      const url = iframes[i].getAttribute("src");

      if (!url || !url.match(youtubeUrlRegex)) {
        // Si la URL no es de YouTube, se elimina el iframe
        iframes[i].parentNode.removeChild(iframes[i]);
      }
    }

    clean = dompurify.sanitize(doc.documentElement.innerHTML, config);
    setContent(clean);

    const img = children.template1?.imagen;
    const title = children.template1?.titulo_imagen;
    setShowImg(img && title);
  }, [children]);

  return (
    <section className={"bg-internacion-gray"}>
      <div className="container-lg pb-5">
        <div className="row pt-5 d-flex justify-content-center">
          {isMobile ? (
            <>
              <div className="col-md-9 col-lg-9">
                {showImg && (
                  <h2 className="internacion__titulo mb-3">
                    {children.template1?.titulo_imagen}
                  </h2>
                )}
                <div className="card b-radius-10 card-contenido-reusable">
                  <div className="card-body p-4">
                    <h2 className="title_reusable_components">
                      {children.template1.titulo}
                    </h2>
                    <hr className="separador_reusable_components" />
                    <div className="contenido">
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                  </div>
                </div>
              </div>
              {showImg && (
                <div className="col-md-3 col-lg-3 mb-3 mt-3">
                  <div className="d-flex flex-column">
                    <img
                      src={children.template1?.imagen?.url}
                      alt="children"
                      className="imagen-template1"
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {showImg && (
                <div className="col-md-3 col-lg-3 mb-3">
                  <h2 className="internacion__titulo">
                    {children.template1?.titulo_imagen}
                  </h2>
                  <div className="d-flex flex-column">
                    <img
                      src={children.template1?.imagen?.url}
                      alt="children"
                      className="imagen-template1"
                    />
                  </div>
                </div>
              )}
              <div className="col-md-9 col-lg-9">
                <div className="card b-radius-10 card-contenido-reusable">
                  <div className="card-body p-4">
                    <h2 className="title_reusable_components">
                      {children.template1.titulo}
                    </h2>
                    <hr className="separador_reusable_components" />
                    <div className="contenido">
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
export default Template1Component;
